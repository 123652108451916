.e5h-connectivity .e5h-connectivity-content {
  overflow-y: auto;
}

.e5h-connectivity .e5h-connectivity-content .e5h-connectivity-components {
  height: 100px;
  overflow: visible;
}

.e5h-connectivity .e5h-connectivity-content .e5h-connectivity-components .bp3-tab-list {
  background-color: #E5F2FF;
}

.e5h-connectivity .e5h-connectivity-content .e5h-connectivity-components .e5h-connectivity-selected-item {
  position: absolute;
  margin: 10px;
  right: 22px;
  z-index: 9;
  max-width: 700px;
  max-height: 445px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25), 5px -5px 10px rgba(0, 0, 0, 0.25), 5px 5px 10px rgba(0, 0, 0, 0.25), -5px -5px 10px rgba(0, 0, 0, 0.25);
}

.e5h-connectivity .bp3-tab-list {
  background-color: transparent !important;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.e5h-connectivity .bp3-tab-list .bp3-tab-indicator-wrapper {
  display: none;
}

.e5h-connectivity .bp3-tab-list > div {
  text-align: center;
  background: rgba(0, 135, 184, 0.1) !important;
  border-radius: 10px;
  flex: 1 1 0;
  color: #0080FF;
  font-weight: 700;
  font-size: 14px;
  border: 1.5px solid transparent;
  padding: 5px 0;
}

.e5h-connectivity .bp3-tab-list div[aria-selected="true"] {
  background: #0080FF !important;
  color: white !important;
}

.e5h-connectivity .bp3-tab-list .initial-tab {
  width: calc((100% - 60px) / 4);
  flex: none;
  margin-bottom: 15px;
}

.e5h-connectivity .bp3-tab-list :nth-child(5) {
  margin-right: 0;
}

.e5h-connectivity .bp3-tab-list > div:hover {
  border: 1.5px solid #0080FF
}
