.e5cbreport {
  position: relative;
  overflow: auto;
  padding: 10px !important;
}

.e5cbreport .maintitle {
  font-size: 1.8em;
  font-weight: bold;
}

.e5cbreport .section {
  font-size: 1.5em;
  font-weight: bold;
}

.e5cbreport .bp3-divider {
  border-color: #000000;
}

.e5cbreport .proc-table {
  overflow-y: auto;
  height: 384px;
}

.e5cbreport .proc-table > div > div {
  font-size: 0.9em;
}

.e5cbreport .proc-table > div > div > div {
  border-top: solid 1px #A0A0A0;
  align-items: center;
  height: 30px;
}

.e5cbreport .proc-table > div > div > div:first-child {
  font-weight: bold;
}

.e5cbreport .proc-table > div > div:first-child {
  border-right: solid 1px #A0A0A0;
}

.e5cbreport > div > div.e5linefull2.e5line-10 > div > div {
  height: 401px;
}

.e5cbreport > div:not(.e5wificb-overview-indic):not(.e5wificb-wifi-indic) > div:not(.proc-table) > div {
  height: 401px;
}

.e5cbreport > div:not(.e5wificb-overview-indic):not(.e5wificb-wifi-indic) > div:not(.proc-table) > div.e5angular-gauge > div {
  align-items: center;
}
