.table {
    width: 100%;
}

.table .MuiDataGrid-root {
    border-radius: 10px;
}

.table .MuiDataGrid-columnHeaders {
    background: #EAEDF1;
    font-weight: bold;
}

.table .MuiDataGrid-columnHeaderTitle {
    font-weight: bold;
}

.table .MuiTablePagination-displayedRows {
    margin: 0
}

.table .MuiTablePagination-selectLabel {
    margin: 0
}

.table .MuiDataGrid-virtualScroller .MuiDataGrid-overlayWrapper {
    min-height: 45px;
    width: 100% !important;
    overflow: hidden !important;
}

.table .MuiDataGrid-virtualScroller .MuiDataGrid-virtualScrollerContent {
    width: 100% !important;
    overflow: hidden !important;
}