.e5wificb-overview-indic {
  padding: 0 !important;
  width: 100%;
  background-color: transparent !important;
  margin-bottom: 15px !important;
}

.e5wificb-overview-indic .wrappable {
  max-width: calc(100vw - 225px);
  width: 100%;
  flex-wrap: wrap;
}

.e5wificb-overview-indic .small{
  flex: 1 1 0;
  background: #FFFFFF;
  border-radius: 10px;
  border: none;
  margin-right: 15px;
}
