.download-users-dialog {
    width: 80%;
    padding-left: 10px;
    padding-right: 10px;
}

.download-users-dialog .bp3-heading {
    margin-bottom: 20px;
    margin-top: 20px
}

.download-users-buttons-wrapper {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
    background-color: #fafcff;
    border-radius: 0 0 6px 6px;
}