.e5wificb-pops-list .e5wificb-pops-header .e5wificb-pops-titleline {
  align-items: center;
  height: 30px;
  color: #0080FF;
  font-weight: 600;
  font-size: 16px;
}

.e5wificb-pops-header {
  border-image-slice: 1;
  border-bottom: 2px solid !important;
  border-image-source: linear-gradient(90deg, #0080FF 0%, #00D6D4 99.92%);
  padding-bottom: 15px;

}

.wificb-pops-item-actions-copy {
  width: 15px;
  height: 29px;
}

.e5wificb-pops-list .e5wificb-pops-header .e5wificb-pops-filterline {
  align-items: flex-end;
}

.e5wificb-pops-list .e5wificb-pops-header .e5wificb-pops-filterline .add-population {
  min-width: 200px;
  margin-left: auto;
  background: rgba(0, 135, 184, 0.1);
  border-radius: 10px;
  color: #0080FF;
  font-size: 14px;
  font-weight: 500;
  border: none;
  box-shadow: none;
  padding: 12px 5px;
}

.e5wificb-pops-list .e5wificb-pops-header .e5wificb-pops-filterline .add-population .bp3-icon {
  color: #0080FF;
}

.e5wificb-pops-list .e5wificb-pops-header .e5wificb-pops-filterline.aclnamefilter {
  font-size: 0.8em;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 60vw;
  gap: 5px;
}

.e5wificb-pops-list .e5wificb-pops-header .e5wificb-pops-filterline.aclnamefilter label.bp3-control {
  margin-bottom: 0;
}


.e5wificb-pops-list .e5wificb-pops-poptableouter {
  overflow: visible;
}

.e5wificb-pops-list .e5wificb-pops-poptableouter .e5wificb-pops-poptableinner {
  width: 100px;
}

.e5wificb-pops-list .bp3-table-column-headers {
  background-color: #EAEDF1;
  font-size: 14px;
  font-weight: 600;
}

.e5wificb-pops-list .e5wificb-pops-poptableouter .e5wificb-pops-poptableinner .e5wificb-pops-poptable {
  border: solid 1px #F3F5F8;
  border-radius: 10px;
}

.e5wificb-pops-list .e5wificb-pops-poptableouter .e5wificb-pops-poptableinner .e5wificb-pops-poptable .tbheadname {
  font-size: 0.8em;
  display: inline-block;
  padding-top: 5px;
}

.e5wificb-pops-list .e5wificb-pops-poptableouter .e5wificb-pops-poptableinner .e5wificb-pops-poptable .tbhead {
  text-align: center;
}

.e5wificb-pops-list .e5wificb-pops-poptableouter .e5wificb-pops-poptableinner .e5wificb-pops-poptable .displaybut {
  color: #0080FF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background: rgba(0, 128, 255, 0.10);
  margin-right: 10px;
  padding: 5px 10px;
}

.e5wificb-pops-list .e5wificb-pops-poptableouter .e5wificb-pops-poptableinner .e5wificb-pops-poptable .downloadbut {
  color: #0080FF;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  padding: 5px 10px;
  background: rgba(0, 128, 255, 0.10);
}

.dialog-filters {
  width: 100%;
  display: flex;
  gap: 15px;
  padding: 15px;
  align-items: center;
  justify-content: space-between;
}


.dialog-footer {
  display: flex;
  width: 100%;
  padding: 15px 15px 0 15px;
  justify-content: space-between;
}

.dialog-footer-button {
  cursor: pointer;
  color: #0080FF;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  height: 35px;
  width: 95px;
  border-radius: 10px;
  background: rgba(0, 128, 255, 0.10);

}

textarea.bp3-input.e5wificb-pops-nilistarea {
  height: 325px;
  margin-inline: 15px;
  width: calc(100% - 30px);
}

.e5wificb-pops-footer-actions .bp3-button:first-of-type {
  margin-left: 0;
}

.e5wificb-pops-confirm {
  padding: 10px;
  width: 200px;
}

.e5wificb-pops-actionspopover {
  padding: 10px;
}

.bp3-dialog-header h4 {
  color: #0080FF;
  text-align: center;
}

.bp3-dialog-footer {
  display: flex;
}

.bp3-dialog-footer-actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.bp3-dialog-footer-action {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.bp3-dialog-footer button {
  background: rgba(0, 135, 184, 0.1) !important;
  border-radius: 10px;
  border: none;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0080FF !important;
}

.bp3-dialog-footer button:hover,
.bp3-dialog-footer button:focus {
  background: transparent !important;
  border: 1.5px solid #0080FF !important;
}

.bp3-button.bp3-intent-primary.admin-acl-save-button {
  color: #2BA217 !important;
  background-color: rgba(43, 162, 23, 0.10) !important;
  margin-left: 0;
}

.bp3-button.bp3-intent-primary.admin-add-button {
  color: #2BA217 !important;
  background-color: rgba(43, 162, 23, 0.10) !important;
  margin-left: 0 !important;
}

.bp3-dialog-footer .admin-acl-save-button {
  background: rgba(0, 135, 184, 0.1) !important;
  border-radius: 10px;
  border: none;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: red !important;
}

.e5wificb-pops-filterbloc input {
  padding: 8.5px 14px !important;
}

.e5wificb-pops-filterbloc .MuiInputBase-root {
  border-radius: 12px;
  height: 40px;
  border-color: #0080FF;
}

/* .e5wificb-pops-filterbloc .MuiInputBase-root:focus {
  border-radius: 12px;
} */

.css-2m9kme-MuiFormControl-root {
  margin: 0 !important;
}

.e5wificb-pops-filterbloc label {
  transform: translate(14px, 9px) scale(1);
}

.e5wificb-pops-filterbloc label.Mui-focused {
  transform: translate(14px, -9px) scale(0.75);
}

.e5wificb-pops-filterbloc label.MuiFormLabel-filled {
  transform: translate(14px, -9px) scale(0.75);
}

.displaybut {
  padding: 5px 10px !important;
  border-radius: 10px;
  background: rgba(0, 128, 255, 0.10) !important;
}

/* .delete-filters-button {
  background: rgba(0, 135, 184, 0.1);
  border-radius: 10px;
  color: #0080FF;
  font-weight: 500;
  font-size: 14px;
  width: 150px;
  border: none;
  box-shadow: none;
  padding: 5px 0;
  margin-bottom: 5px !important;
} */

.e5wificb-pops-filterline .delete-filters-button {
  background: rgba(0, 135, 184, 0.1) !important;
  border-radius: 10px;
  color: #0080FF !important;
  font-weight: 500;
  font-size: 14px;
  min-width: 150px;
  width: 150px;
  border: none;
  box-shadow: none !important;
  padding: 5px 0;
  margin-bottom: 5px !important;
}

.e5wificb-pops-checkbox-wrapper {
  border: 1px solid lightgray;
  padding-top: 10px;
  padding-left: 5px;
}

.e5wificb-pops-filterbloc-date {
  width: 112px
}

.e5wificb-pops-filterbloc .css-1mzc8 {
  margin: '0 !important'
}

.MuiFormControl-root.css-1mzc8 {
  margin: '0 !important'
}

.css-1mzc8 {
  margin: '0 !important'
}

.e5wificb-pops-confirm-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.wificb-pops-popup-dialog.bp3-dialog {
  background-color: '#fafcff !important';
}

.e5wificb-pops-flex-between {
  display: flex;
  justify-content: space-between;
}

.wificb-pops-list-wrapper {
  padding-left: 16px;
  padding-right: 12px;
  padding-top: 20px;
  height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.wificb-pops-item-wrapper {
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

}

.wificb-pops-item-wrapper:hover {
  background-color: WhiteSmoke;
}

.wificb-pops-item-text {
  margin: 0;
  padding-top: 7px;
  padding-bottom: 7px;
  padding-left: 5px;
}

.wificb-pops-item-actions-wrapper img:hover {
  cursor: pointer;
}

.wificb-pops-item-actions-eye {
  margin-right: 10px;
  width: 17px;
  height: 12px;
}

.wificb-pops-item-actions-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
}

.wificb-pops-item-actions-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}