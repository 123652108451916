.e5wificb-pops-popup .e5wificb-pops-crittable {
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 10px;
}

.e5wificb-pops-popup .e5wificb-pops-crittable .tbheadname {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.e5wificb-pops-popup .e5wificb-pops-crittable .bp3-table-cell {
  box-shadow: inset 0 -1px 0 #f4f5f8, inset -1px 0 0 #f4f5f8;
}

.e5wificb-pops-popup .e5wificb-pops-crittable .tbhead {
  text-align: center;
  border-right: 1px solid #f4f5f8;
  font-weight: bold;
}

.e5wificb-pops-popup .e5line-10 {
  margin-bottom: 10px;
}

.e5wificb-pops-popup .e5wificb-pops-crittable .bp3-table-column-header-tr {
  background-color: rgba(224, 224, 224, 1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.wideselect {
  width: 460px;
}

.wificb-pops-netids-txt {
  min-height: 300px;
  resize: none;
}

.wificb-pops-dial-row {
  margin-bottom: 20px !important;
}


.wificb-pops-dial-row button {
  background: rgba(0, 135, 184, 0.1) !important;
  border-radius: 10px;
  border: none;
  box-shadow: none !important;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #0080FF !important;
}

.e5wificb-pops-list .bp3-dialog {
  background-color: #fafcff !important;
}

.e5wificb-pops-list .bp3-dialog-body {
  background: #fafcff !important;
}

.e5wificb-pops-list .bp3-dialog-body .bp3-table-quadrant-main {
  background-color: white !important;
}

.bp3-dialog {
  background-color: white !important;
}

.bp3-dialog-body {
  background: white !important;
}

.bp3-dialog-body .bp3-table-quadrant-main {
  background-color: white !important;
}

.bp3-dialog-body input {
  background: #FFFFFF;
  border: 1px solid #0080FF;
  border-radius: 10px;
  color: black;
}

.bp3-dialog-body select {
  background: #FFFFFF;
  border: 1px solid #0080FF;
  border-radius: 10px;
  color: #0080FF;
}

.e5wificb-pops-popup .bp3-form-group {
  position: relative;
}

.e5wificb-pops-popup .bp3-form-group label.bp3-label {
  font-size: 13px;
  position: absolute;
  background-color: #F9FCFF;
  z-index: 2;
  color: #0080FF;
  line-height: 13px;
  left: 10px;
  transform: translateY(-50%);
}

.criterion-inputs {
  position: relative;
  margin-bottom: 30px;
}

.criterion-inputs .bp3-label {
  font-size: 13px;
  position: absolute;
  background-color: #F9FCFF;
  z-index: 2;
  color: #0080FF;
  line-height: 13px;
  left: 10px;
  transform: translateY(-50%);
}

.wificb-pops-condition-wrapper {
  padding: 15px;
  border: 1px solid #0080FF;
  border-radius: 10px;
  width: 100%;

}

.wificb-pops-criteria-select {
  width: 100%;
}

.wificb-pops-criteria-select .bp3-icon-double-caret-vertical {
  color: #0080FF
}

.wificb-pops-acl-select .bp3-icon-double-caret-vertical {
  color: #0080FF
}

.bp3-form-group label.bp3-label {}