.e5admin-users-popup .bp3-icon-lock {
  padding: 4px 4px 0 0;
}

.aclgroups {
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;

}

.aclgroups::-webkit-scrollbar {
  width: 0;
}

.aclgroups>* {
  margin: 0;
}

button.bp3-button.admin-users-aclgroups-select {
  width: 225px;
}

.expiration-form-group .bp3-input {
  width: 225px !important;
}

.aclgroups>*:not(:last-child) {
  padding-bottom: 10px;
}

.admin-users-aclgroups-select {
  background-color: white !important;
  border: 1px solid #0080FF;
  border-radius: 10px;
  color: #0080FF !important;
}

.bp3-control-indicator {
  border-radius: 10px !important;
}

.bp3-form-content {
  display: flex
}

.roles_question {
  font-weight: bold;
  border: 1px solid black;
  display: inline-flex;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  text-align: center;
  position: relative;
  /* top: -.5em; */
  padding: 0px 5px;
  border-color: #0080FF;
  color: #0080FF;

}

.e5admin-users-popup .label {
  font-size: 13px;
  position: absolute;
  background-color: #F9FCFF;
  z-index: 2;
  color: #0080FF;
  line-height: 13px;
  left: 14px;
  transform: translateY(-50%);
}

.acl-form-group {
  position: relative;
}