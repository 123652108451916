.e5categmenuitem {
  font-size: 16px;
  font-weight: bold;
  padding-left: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.e5menuicon{
  width: 30px;
}

.e5menuitem:hover{
  border-radius: 10px;
  background: rgba(0, 128, 255, 0.07);
  color:#0080FF;
  fill:#0080FF;
}

.bp3-menu-item:hover{
  background: none !important;
}

/* .e5categmenuitem::before{
  content: '';
  width: 100%;
  border: 0.5px solid #595959;
  height: 0;
}

.e5categmenuitem::after{
  content: '';
  width: 100%;
  border: 0.5px solid #595959;
  height: 0;
} */

.e5categmenuitem.narrowitem {
  padding-left: inherit;
}

.e5categmenuitem * {
  color: #595959;
  fill: #595959;
}

.e5menuitem {
  margin: 5px 0;
  padding-left: 5px;
  font-size: 15px;
  font-weight: 500;
  align-items: center;
}

.activeitem{
  border-radius: 10px;
  background: rgba(0, 128, 255, 0.07);
  color:#0080FF;
  fill: #0080FF;
}


.e5menuitem.narrowitem {
  padding-left: inherit;
}

.e5submenuitem {
  padding-left: 30px;
}

.e5submenuitem.narrowitem {
  padding-left: inherit;
}

.e5menuimage {
  width: 30px;
  height: 20px;
  cursor: pointer;
  fill: #595959;
}

.e5menuimage:focus {
  outline: none;
}

.e5menuimage:hover {
  opacity: 0.8;
}

.e5menuimage:hover.disabled {
  opacity: 1;
}

.e5menuimage.disabled {
  cursor: default;
}

.e5menuitem .bp3-popover-target{
  display: flex;
  justify-content: center;
  align-items: center;
}
