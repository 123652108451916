.home-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    background: #F9FCFF;
    align-items: center;
    padding-inline: 4vh;
}

.home-page .switch-container {
    width: max-content;
    height: 4.5vh;
    border-radius: 30px;
}


.home-page .switch-container .button {
    font-size: 1.8vh !important;
    font-weight: 700;
    border-radius: 30px;
}

.home-page-icon {
    width: auto;
    height: 5ovh;
}

.home-page-left-container {
    display: flex;
    flex-direction: column;
    width: 45%;
    gap: 7vh;
}

.home-page-title {
    color: #0080FF;
    font-size: 4vh;
    font-weight: 700;
}

.wificb-dashboard-ids-list {
    margin-top: 50px;
    position: absolute;
    width: 340px;
    max-height: 96px;
    min-height: 0px;
    color: black;
    background-color: white;
    overflow: scroll;
    overflow-x: hidden;
}

.wificb-dashboard-ids-list-item {
    min-height: 'auto';
    display: 'flex';
    justify-content: "space-between";
    align-items: 'baseline';
    padding: 0;
}

.wificb-dashboard-search-svg {
    width: 20px;
    height: 20px;
    position: absolute;
    margin-left: -25px;
    margin-top: 20px;
}

.wificb-dashboard-search-label {
    margin-left: 20px;
}

.wificb-dashboard-search-wrapper {
    display: flex;
}

.wificb-dashboard-search {
    width: 360px;
}