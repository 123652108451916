.equipments-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #F9FCFF;

}

.equip-connected-stations .content-item-value {
    font-weight: bold;
}

.title-item-label-with-margin {
    margin-left: 15px;
}

.equipments-content {
    width: 100%;
    height: calc(100% - 133px - 20px - 50px - 20px);
    overflow-y: auto;
    border-image-slice: 1;
    border-top: 2px solid !important;
    border-image-source: linear-gradient(90deg, #0080FF 0%, #00D6D4 99.92%);
    padding-top: 15px;

}

.equipments-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: 15px;
    height: 35px;
}

.equipments-list {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.equipments-list-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    border-radius: 10px;
}

.list-item-header {
    width: 100%;
    height: 69px;
    display: flex;
    align-items: center;
    padding: 10px;
    background: #F3F5F8;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
}

.closed-header {
    border-radius: 10px;
}

.equip-list-item-content {
    width: 100%;
    padding: 10px;
    display: none;
    flex-direction: column;
    background: white;
}

.equip-top-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 90px;
    width: 100%;
}

.equip-bot-content {
    margin-top: 25px;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 40px;
}

.content-item {
    width: 50%;
    height: 30px;
    display: flex;
    align-items: center;
}

.equip-content-item-label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 180px;
}

.content-item-value {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.show-details {
    display: flex;
}

.header-image {
    width: 30px;
    height: 34px;
    margin-right: 15px;
}

.equip-header-title-container {
    width: calc(100% - 11px - 12px - 20px - 30px - 15px);
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.title-item {
    width: 50%;
    height: 50%;
    display: flex;
    align-items: center;
}

.title-item-label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 5px;
}

.title-item-content {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.header-metrics {
    height: auto;
    width: 20px;
    margin-right: 12px;
    cursor: pointer;
}

.header-chevron {
    width: 11px;
    height: auto;
}

.equip-chevron {
    width: 11px;
    height: auto;
    margin-right: 10px;
}

.equip-content-toggle {
    color: black;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.equip-content-toggle-empty {
    color: black;
    font-size: 14px;
    font-weight: 600;
}

.interface-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 15px;
    background: #FFF;
}

.interface-item-header {
    width: 100%;
    height: 100px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px 10px 0;
    background: #F3F5F8;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
    position: relative;
}

.interface-chevron {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateX(-50%);
    width: 11px;
    height: auto;
}

.content-details {
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #d5d5d5;
}

.interfaces-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}


.interface-text-item {
    width: 50%;
    height: 30px;
    display: flex;
    gap: 5px;
}

.interface-text-item-label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
}

.interface-text-item-value {
    color: #000;
    font-size: 14px;
    font-weight: 600;
    max-width: 200px;
    word-break: break-word;
}

.interface-item-content {
    width: 100%;
    display: flex;
    padding: 10px;
}

.interface-item-content .interface-text-item {
    width: 100%;
    min-height: 40px;
    display: flex;
    align-items: center;
}

.interface-item-content-data {
    width: 25%;
    display: flex;
    flex-direction: column;
}


.content-data-label {
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-decoration-line: underline;
    height: 30px;
}


.system-list {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.system-list-label {
    width: 100%;
    color: black;
    height: 50px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
}


.system-list-scores,
.system-list-tables {
    width: 100%;
    overflow: hidden;
    display: flex;
    margin-top: 15px;
    gap: 25px;
}

.system-list-scores {
    min-width: 1000px;
}

.system-list-scores-item,
.system-list-tables-item {
    width: 50%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.system-list-charts {
    width: 100%;
    display: flex;
    margin-top: 15px;
}

.d-none {
    display: none;
}

.no-data-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
}

.no-data-wrapper .no-data {
    display: flex;
}

.system-list-charts-item {
    width: 33%;
    display: flex;
    justify-content: center;
}