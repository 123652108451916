.incident-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    margin-bottom: 15px;
    background-color: white;
}

.incident-item-header {
    width: 100%;
    height: 69px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 10px;
    flex-wrap: wrap;
    background: #F3F5F8;
    border-radius: 10px 10px 0px 0px;
}

.incident-title-item {
    height: 30px;
    width: 50%;
    display: flex;
    align-items: center;
}

.incident-title-item-label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 5px;
}

.incident-title-item-content {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.indident-item-content {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 10px;
    border-radius: 10px;
}

.incident-item-content-top {
    display: flex;
    justify-content: space-between;
}

.incident-item-content-bot {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 40px;
}

.incident-content-item {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.incident-content-item.small {
    width: 20%;
}

.incident-list-value {
    color: #000;
    font-size: 14px;
    font-weight: 600;
}

.incident-content-item .incident-title-item {
    width: 100%;
}

.indident-content-item-title {
    display: flex;
    align-items: center;
    height: 30px;
    color: #000;
    font-size: 14px;
    font-weight: 500;
    text-decoration-line: underline;
}

.incident-item-content-toggle {
    color: black;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.incident-content-details {
    width: 100%;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #d5d5d5;
}

.recommendations-container {
    width: 100%;
    display: flex;
}

.recommendation-item {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.recommendation-item .incident-title-item {
    width: 100%;
}

.charts-container {
    width: 100%;
    display: flex;
    gap: 20px;
}

.charts-container>div {
    width: 100%;
}

.incident-content-item-mock {
    width: 125px;
}

.incident-criticality-wrapper {
    display: flex;
    align-items: baseline;
}

.incident-criticality {
    width: 60px;
    height: 8px;
    border-radius: 10px;
    background-color: rgb(25, 185, 143);
    margin-right: 10px;
    margin-top: 2px;
    border-radius: 5px;
    padding: 0px 5px 0px 6px;
}