.e5h-metric-wan p {
  margin-bottom: 0;
}

.e5h-metric-wan .align-content-center {
  display: flex;
  align-items: center;
}

.e5h-metric-wan .content-box {
  display: grid;
  grid-template-columns: 0.5fr 1fr;
}

.e5h-metric-wan .chart-height {
  height: 350px;
}