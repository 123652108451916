.username {
    font-size: 18px;
    font-weight: 600;
}

.e5admintext {
    color: #C80000;
    background-color: hsla(0, 100%, 39%, 0.1);
    padding: 7px 10px 7px 10px;
    width: max-content;
    border-radius: 15px;
}