.e5wifi-instability {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px 20px 20px;
  overflow: auto;
}

.e5wifi-instability .legend {
  font-size: 0.9em;
  padding: 10px 5px;
  margin: 0 auto;
  border-radius: 10px;
  align-self: center;
}

.legend-wrapper {
  display: flex;
}

.e5wifi-instability .legend .legendrssi {
  width: 26px;
  height: 13px;
  border: solid 1px #000000;
}

.e5wifi-instability .legend .legendrssi.rssired {
  background-color: #ff0000;
}

.e5wifi-instability .legend .legendrssi.rssiorange {
  background-color: #d9822b;
}

.e5wifi-instability .legend .legendrssi.rssigreen {
  background-color: #15b371;
}

.e5wifi-instability .e5wifi-instability-inner {
  overflow: visible;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 10px;
  height: 100%;
}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table {
  border-spacing: 0px;
  border-radius: 10px;


}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table td {
  padding: 3px;
  height: 45px;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  overflow-wrap: break-word;
  border: unset;
  border-bottom: solid 1px #F3F5F8;
  border-right: solid 1px #F3F5F8;
}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table {
  border-spacing: 0px;
  border-radius: 10px;
}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table .header {
  background: #EAEDF1;
  font-weight: 600;
}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table td.header.node,
.e5wifi-instability .e5wifi-instability-inner .e5insta-table td.header.band {
  text-align: center;
}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table td.header.netelem.nodes,
.e5wifi-instability .e5wifi-instability-inner .e5insta-table td.header.netelem.bands {
  text-align: right;
}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table td.value {
  text-align: center;
  vertical-align: middle;
  width: 90px;
  min-width: 90px;
}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table td.value .bp3-tag {
  font-size: 0.9em;
}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table td.value.selected {
  background-color: #FFCCAA;
}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table td.value.red {
  background-color: #ff0000;
}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table td.value.orange {
  background-color: #d9822b;
}

.e5wifi-instability .e5wifi-instability-inner .e5insta-table td.value.green {
  background-color: #15b371;
}

.e5wifi-instability .e5wifi-instability-inner .bp3-spinner {
  display: inline-block;
}