.e5cb-sys-process {
  height: 100px;
  padding-right: 20px;
}

.e5cb-sys-process .proc-table {
  overflow-y: auto;
  height: 410px;
}

.e5cb-sys-process .proc-table>div>div {
  font-size: 0.9em;
}

.most-intensive-no-data {
  height: 410px
}

.e5cb-sys-process .proc-table>div>div>div {
  border-top: solid 1px rgba(224, 224, 224, 1);
  align-items: center;
  height: 30px;
}

.e5cb-sys-process .proc-table>div>div>div:first-child {
  font-weight: bold;
}

.e5cb-sys-process .proc-table>div>div:first-child {
  border-right: solid 1px rgba(224, 224, 224, 1);
}

.e5cb-sys-process>div>div:not(.proc-table)>div {
  height: 410px;
}

.e5cb-sys-process>div>div:not(.proc-table)>div.e5angular-gauge>div {
  align-items: center;
}

.e5border {
  table-layout: fixed;
  border: 2px solid cornflowerblue;
}

.left-header {
  justify-content: center;
  background-color: rgba(224, 224, 224, 1);
  border-top-left-radius: 8px;
}

.right-header {
  justify-content: center;
  background-color: rgba(224, 224, 224, 1);
  border-top-right-radius: 8px;
}

.cb-sys-circle {
  border: 2px solid #f00;
  border-radius: 100px;
  height: 13px;
  width: 13px;
  margin-right: 5px !important;
}