.date-filter {
    justify-content: flex-end;
}

.device-count-filter {
    justify-content: center;
}

.device-type-filter {
    justify-content: flex-start;
}

.toggle {
    display: flex;
    background: #F9FCFF;
    border: 1px solid rgba(0, 135, 184, 0.5);
    border-radius: 15px;
}

.toggle .toggle-item {
    padding: 5px 10px;
    background: #F9FCFF;
    border-radius: 15px;
    color: rgba(0, 135, 184, 0.5);
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    border: none;
}

.toggle .active {
    background: #0080FF;
    color: white;
}

.e5hactivity-box {
    background-color: #FFF;
    padding: 36px;
    text-align: center;
}

.e5hactivity-graph-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 19.5px;
    text-align: center;
    color: #000000;
}

.e5hactivity-graph-legend-title {
   font-weight: 700;
}

.e5hactivity-legend-usage {
    content: '';
    background: linear-gradient(90deg, #5E6BC0 0%, #EF5352 100%);
    border-radius: 5px;
    width: 100px;
    height: 15px;
    border: 1.5px solid #eee;
    margin-left: 10px;
    margin-right: 10px;
}

.e5hactivity-legend-notreceived {
    content: '';
    background: #bebebd;
    border-radius: 5px;
    width: 25px;
    height: 15px;
    border: 1.5px solid #eee;
    margin-left: 10px;
    margin-right: 10px;
}

.e5hactivity-legend-low-activity {
    content: '';
    background: #5E6BC0;
    border-radius: 5px;
    width: 25px;
    height: 15px;
    margin-left: 10px;
    margin-right: 10px;
}

.e5hactivity-legend-high-activity {
    content: '';
    background: #EF5352;
    border-radius: 5px;
    width: 25px;
    height: 15px;
    margin-left: 10px;
    margin-right: 10px;
}

.e5hactivity-legend-activity-space {
    content: '';
    background: linear-gradient(0deg, #5E6BC0 0%, #EF5352 100%);
    border-radius: 3px;
    width: 15px;
    height: 100px;
    margin-left: 10px;
    margin-right: 10px;
}

.e5hactivity-legend {
    display: flex;
    align-items: center;
}

.e5hactivity-legend-centered {
    justify-content: center;
}

.e5hactivity-legend-spacement {
    margin-left: 20px;
    margin-right: 20px;
}

.e5hactivity-boxes-spacement {
    margin-top: 15px;
}

.e5hbardonutgraph {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.e5hactivity-grid-two-columns {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
}

.no-data {
    color: #595959;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    margin: 20px 0 20px 0 !important;
}