html,
body,
#root {
  margin: 0;
  height: 100%;
  font-family: "Montserrat", sans-serif;
}

* {
  font-family: "Montserrat", sans-serif !;
}

a {
  color: inherit;
}

.link a {
  color: #6666CC;
}

@media print {
  html {
    zoom: 50%;
  }
}

/*.bp3-overlay-backdrop {
	background-color: rgba(235, 235, 235, 0.3);
	position: absolute;
}*/
.bp3-navbar-heading,
.bp3-button,
.bp3-input,
.bp3-html-select select {
  font-size: inherit;
  font-family: inherit;
}


input.bp3-input::placeholder {
  color: #0080FF;
}

textarea.bp3-input::placeholder {
  color: #0080FF;
}

.bp3-tabs {
  display: flex;
  flex-direction: column;
}

.bp3-tabs .bp3-tab-list {
  padding: 0 5px 17px 5px;
  padding-left: 0;
  border-image-slice: 1;
  border-bottom: 2px solid !important;
  border-image-source: linear-gradient(90deg, #0080FF 0%, #00D6D4 99.92%);
}

.bp3-tabs .bp3-tab-list .bp3-tab[aria-selected="true"] {
  color: #0059b3;
}

.bp3-tabs .bp3-tab-panel {
  flex: 1;
  margin: 0;
}

.bp3-menu a.bp3-menu-item.bp3-disabled {
  color: #BBCCFF !important;
}

.bp3-menu-item.bp3-intent-primary.bp3-active {
  background-color: transparent;
  font-weight: 500;
  color: #0080FF;
  /* color: white; */
}

.bp3-dialog {
  background-color: #E5F2FF;
  padding-bottom: 10px;
  /* padding-bottom: 0; */
}

.bp3-dialog-header {
  box-shadow: none;
}

.bp3-dialog-body {
  margin-left: 10px;
  margin-right: 10px;
}

.bp3-dialog-footer {
  margin: 0 10px;
}

.bp3-button.bp3-small span.bp3-icon {
  margin: 0 -4px !important;
}

.bp3-button.e5linkbutton {
  min-height: 14px;
  color: #0080FF;
  padding: 0 0 4px 0;
}

.bp3-button.e5linkbutton:focus {
  outline: none;
}