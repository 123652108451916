.e5mainheader {
  padding: 5px;
  align-items: center;
  background: #FFF;
  margin: 0;
  height: 60px;
}

.e5mainheader.e5line-10 {
  margin: 0;
}

.e5mainheader .logo {
  height: 30px;
  width: 180px;
  margin: 0;
  cursor: pointer;
}

.burgermenu {
  color: #0080FF;
}

.burgermenu svg {
  height: 24px;
  width: auto;
}

.headermenu .welcome {
  color: #0080FF;
  font-weight: 600;
  font-size: 14px;
}

.headermenu .switch-container {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
  border: none;
  padding-inline: 5px;
}

.headermenu .switch-container .theme-switch-content {
  display: flex;
  flex-direction: row;
  margin-left: .5rem;
}

.headermenu .switch-container .theme-switch-content .theme-switch-light-icon {
  margin-right: 7px;
}

.header-link {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
  height: 30px;
  padding-inline: 5px;
  cursor: pointer;
}

.header-link:hover {
  border-radius: 10px;
  background: #F1F1F1;
}

.headermenu .switch-container .bp3-switch {
  margin: 0;
}

.headermenu .MuiFormControl-root {
  width: 100%;
  margin: 0 10px 0 10px
}

.headermenu .disconnect-button {
  color: #FF0000;
  font-size: 14px;
  font-weight: 500;
  background: rgba(255, 0, 0, 0.1);
  border-radius: 10px;
  border: none;
  box-shadow: none;
}

.headermenu .disconnect-button:hover {
  border: 1.5px solid #FF0000;
  background: rgba(255, 0, 0, 0.1);
}

.e5mainheader .sectiontitle {
  padding: 0 0 0 12px;
  font-size: 1.7em;
  color: #0080FF;
}

.e5mainheader .e5custlogo {
  height: 30px;
  padding: 2px;
}

.e5mainheader .headerbutton,
.e5mainheader .headerbutton:hover,
.e5mainheader .headerbutton:not(:hover) {
  padding: 0;
  min-height: auto;
  border-radius: 0;
  color: #FFFFFF;
  border-bottom: 1px solid #FFFFFF;
}

.e5flag {
  width: 20px;
  margin-top: 3px;
}

.headermenu {
  padding: 10px;
}

.headermenu div {
  align-items: center;
}

.theme-switch-select {
  border: '1px solid red';
  width: 100%;
}

.headermenu-v3 {
  padding: 10px 0;
}

.header-link-v3 {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: black;
  height: 30px;
  padding-inline: 5px;
  cursor: pointer;
  text-align: center;
}

.header-link-v3:hover {
  background: #F1F1F1;
}

.header-link-v3 img + span {
  margin-left: 5px;
}

.user {
  cursor: pointer;
}