.device-img {
    height: 50px;
}

.equip-connected-stations {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #FAFAFA;
    border-radius: 10px;
    margin-bottom: 10px;
}

.system-metric-chart-container {
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    column-gap: 10px;
    margin-top: 20px;
}

.system-metric-chart-container .description-text{
    display: flex;
    align-content: center;
    flex-wrap: wrap;
}

.system-metric-chart-container .chart {
    content: '';
    width: 100%;
    background-color: #63ABFD;
    height: 40px;
    border: 2px solid #899199;
    display: flex;
    align-items: center;
}

.system-metric-chart-container .chart .chart-percentage {
    content: '';
    height: 10px;
    background-color: #2e3033;
}

.system-metrics-spacement {
    margin-top: 20px;
}

.over-capacity {
    color: #eb4034;
}
