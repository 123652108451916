.e5wifih-metrics-indic {
  padding: 0 !important;
  width: 100%;
  background-color: transparent !important;
  margin-bottom: 15px !important;
}

.e5wifih-metrics-indic .wrappable {
  max-width: calc(100vw - 225px);
  width: 100%;
  flex-wrap: wrap;
}

.e5wifih-metrics-indic .small{
  width: 200px;
  background: #FFFFFF;
  border-radius: 10px;
  border: none;
  margin-right: 15px;
}

.e5wifih-metrics-indic-children{
  display: flex;
  gap:10px;
  align-self:center;
  margin-left: auto !important;
}
