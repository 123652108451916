.switch-container {
    display: flex;
    border-radius: 15px;
    border: 1px solid rgba(0, 128, 255, 0.50);
    background: #FFF;
    height: 30px;
    align-items: center;
}

.switch-container .button {
    border-radius: 15px;
    color: rgba(0, 128, 255, 0.50);
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
}

.switch-container .active {
    border-radius: 15px;
    background: #0080FF;
    color: white;
    text-align: center;
    font-size: 12px;
    font-weight: 700;
}

.with-padding {
    padding-right: 150px;
}

.e5xy-chart {
    height: 100%;
    position: relative;
}

.top-left-container {
    position: absolute;
    left: 0;
    top: 50px;
    z-index: 100;
}

.subtitlenormal {
    font-size: 0.9em;
    text-align: center;
    color: #707070;
    margin-top: 5px;
}