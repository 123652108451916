.e5wifih-dashboard-indic {
  padding: 0 !important;
  background-color: transparent !important;
  flex-wrap: wrap;
}

.e5wifih-dashboard-indic .e5text-indic{
  width: fit-content;
}

.e5wifih-dashboard-indic .e5text-indic .bp3-popover-target{
  width: fit-content;
}

.e5wifih-dashboard-indic .e5text-indic .label-container{
  margin: 0 0 0 5px;
}

.e5wifih-dashboard-indic .small{
  background: #FFFFFF;
  border-radius: 10px;
  border: none;
  margin-right: 15px;
}