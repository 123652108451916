.wildcard-routes {
    height: 100%;
    background: #F4F7F9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem;
}

.wildcard-page-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
}

.wildcard-page-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.wildcard-page-title {
    color: #0080FF;
    font-size: 54px;
    font-weight: 700;
}

.wildcard-page-description {
    color: #333;
    font-size: 24px;
    font-weight: 400;
}

.wildcard-page-icon {
    width: 100%;
    min-width: 400px;
    max-height: 600px;
    height: auto;
}

@media (max-width: 1140px) {
    .wildcard-page-container {
        grid-template-columns: 1fr;
    }

    .wildcard-page-text-container {
        text-align: center;
    }

    .wildcard-page-title {
        font-size: 36px;
    }

    .wildcard-page-description {
        font-size: 18px;
    }
}