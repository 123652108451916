.e5cb-sys-reboot {
  height: 100px;
  padding-right: 20px;
}

.e5cb-sys-reboot > div > div > div {
  height: 384px;
}

.e5cb-sys-reboot > div > div > div.e5angular-gauge > div {
  align-items: center;
}

.e5cb-sys-reboot > div:first-child > div > div,
.e5cb-sys-reboot > div:nth-child(2) > div > div {
  height: 400px;
}

.e5cb-sys-reboot .e5pie-chart {
  height: 400px;
}
