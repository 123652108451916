.e5wifih-dashboard .e5wifih-dashboard-content {
  overflow-y: auto;
  overflow-x: hidden;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components {
  height: 100px;
  overflow: visible;
  position: relative;
  min-width: 950px;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .weekscores {
  display: grid;
  grid-template-columns: 9% repeat(7, 13%);
  height: 180px;
  border-radius: 10px;
  border: 1px solid #F3F5F8;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .weekscores .dayscores:first-child .scorediv:first-child {
  border-top-left-radius: 10px;
}


.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .weekscores .dayscores:last-child .scorediv:first-child {
  border-top-right-radius: 10px;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .weekscores .dayscores:not(:first-child) {
  border-left: solid 1px #F3F5F8;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .weekscores .scorediv {
  height: 45px;
  padding: 10px 0 10px 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .weekscores .scorediv.text {
  padding-left: 0;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .weekscores .scorediv.small {
  font-size: 0.8em;
  padding-top: 12px;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .weekscores .scorediv:first-child {
  background-color: #EAEDF1;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .weekscores .scorediv:not(:first-child) {
  border-top: solid 1px #F3F5F8;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .weekscores .scorediv .scoreimg {
  height: 15px;
  width: 15px;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .topowait {
  position: absolute;
  right: 5px;
  top: 8px;
  margin-right: 0;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .bp3-tab-list {
  background-color: #E5F2FF;
  margin-bottom: 20px;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .dashboard-tab {
  min-width: 580px;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .anom-clickable {
  font-size: 0.9em;
  background: #FFFFFF;
  padding: 5px;
  border: solid 1px #A0A0A0;
  border-radius: 5px;
  overflow: hidden;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .anom-clickable .e5spinwait {
  align-self: center;
  padding-left: 5px;
}

.e5wifih-dashboard .e5wifih-dashboard-content .e5wifih-dashboard-components .anom-clickable .anomerror {
  align-self: center;
  color: red;
}


.e5wifih-dashboard .bp3-tab-list {
  background-color: transparent !important;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.e5wifih-dashboard .bp3-tab-list .bp3-tab-indicator-wrapper {
  display: none;
}

.e5wifih-dashboard .bp3-tab-list>div {
  text-align: center;
  background: #e0f1ff !important;
  border-radius: 10px;
  flex: 1 1 0;
  color: #0080FF;
  font-weight: 700;
  font-size: 14px;
  border: 1.5px solid transparent;
  padding: 5px 0;
}

.e5wifih-dashboard .bp3-tab-list div[aria-selected="true"] {
  background: #0080FF !important;
  color: white !important;
}

.e5wifih-dashboard .bp3-tab-list .initial-tab {
  width: calc((100% - 60px) / 4);
  flex: none;
  margin-bottom: 15px;
}

.e5wifih-dashboard .bp3-tab-list :nth-child(5) {
  margin-right: 0;
}

.e5wifih-dashboard .bp3-tab-list>div:hover {
  border: 1.5px solid #0080FF
}


.dashboard-health-container {
  width: 100%;
  display: flex;
  gap: 15px;
}


.dashboard-health-container>div {
  width: 33%;
}

.dashboard-filters {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.bar-charts-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.top-chart-header {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popover-info-list {
  padding: 1rem;
}

.popover-info-list .popover-info-item {
  font-size: 10px;
  margin: 0;
  font-weight: bold;
}

.popover-info-list .popover-info-item + .popover-info-item {
  margin-top: 0.5rem;
}

.e5hhealth-top-chart-content {
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
}