.e5cb-sys-memory {
  height: 100px;
  padding-right: 20px;
}

.e5cb-sys-memory > div > div > div {
  height: 384px;
}

.e5cb-sys-memory > div > div > div.e5angular-gauge > div {
  align-items: center;
}

.e5cb-sys-memory > div:first-child > div > div {
  height: 400px;
}

.e5cb-sys-memory .e5pie-chart {
  height: 400px;
}
