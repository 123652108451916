.e5h-equipsys .bp3-spinner {
  padding-left: 5px;
  align-self: center;
}

.e5h-equipsys .dot {
  padding: 0 10px;
  font-size: 2em;
  line-height: 0.5em;
}

.e5h-equipsys .value {
  font-weight: bold;
  font-size: 1.1em;
}

.e5h-equipsys .value.small {
  font-size: 0.9em;
}

.e5h-equipsys-table {
  width: 100%;
  display: grid;
  font-size: 0.9em;
}

.e5h-equipsys-table.proc-table {
  grid-template-columns: 12% 20% 12% 12% 12% 20%;
}

.e5h-equipsys-table.crash-table {
  grid-template-columns: 15% 30% 15% 40%;
}

.e5h-equipsys-table.table-header {
  margin-top: 5px;
  font-weight: 600;
  background-color: #EAEDF1;
  border-radius: 10px 10px 0px 0px;
}

.e5h-equipsys-table.table-rows {
  height: 300px;
  overflow: hidden overlay;
  border-radius: 0 0 10px 10px;
  border: 2px solid #F3F5F8;
}

.e5h-equipsys-table > div:not(:first-child) {
  border-left: solid 1px #F3F5F8;
}

.e5h-equipsys-table > div > div {
  height: 28px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.e5h-equipsys-table.table-header > div {
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.e5h-equipsys-table > div > div:not(:first-child) {
  border-top: solid 1px #F3F5F8;
}
