.e5admin-acl-popup textarea.bp3-input {
  width: 100%;
  resize: none;
  height: 100px;
}

.e5admin-acl-popup .bp3-form-group .bp3-icon.bp3-icon-star {
  padding-bottom: 8px;
}

.e5admin-acl-popup .bp3-checkbox {
  margin-top: 5px !important;
  margin-bottom: 0;
}

.e5admin-acl-popup .default-exists {
  padding-top: 7px;
}

.admin-acl-default {
  display: flex;
  flex-direction: row;
  text-align: center;
  margin: 0;
  padding: 0;
  align-items: baseline;
}

.admin-acl-default-checkbox {
  margin-left: 10px !important;
}

.bp3-input-group.bp3-disabled {
  width: 100% !important;
}

.bp3-input-group {
  width: 100%;
}

.padding-bottom-10 {
  padding-bottom: 10px;
}

.admin-acl-default .bp3-label {
  color: #0080FF;
}

.bp3-dialog.delete-acl-modal {
  padding-bottom: 0 !important;
}

.delete-acl-modal .bp3-dialog-header {
  background-color: #fafcff;
  min-height: 60px !important;
}

.e5admin-acl-popup .bp3-input:disabled,
.bp3-input.bp3-disabled {
  background-color: white !important;

}

.e5admin-acl-popup .label {
  font-size: 13px;
  position: absolute;
  background-color: #F9FCFF;
  z-index: 2;
  color: #0080FF;
  line-height: 13px;
  left: 14px;
  transform: translateY(-50%);
}

textarea.bp3-input {
  border: 1px solid #0080FF !important;
  border-radius: 6px;
}

.e5admin-acl-filterbloc-date {
  width: 112px;
}