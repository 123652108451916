.e5wifih-metrics-process .process-select select{
    border-radius: 10px;
    border: 1px solid #0080FF;
    background: #FFF;
    color: #0080FF;
}

.e5wifih-metrics-process .filterbloc label{
    transform: translate(14px, 9px) scale(1);
  }
  
  .e5wifih-metrics-process .filterbloc label.Mui-focused {
    transform: translate(14px, -9px) scale(0.75);
  }
  
  .e5wifih-metrics-process .filterbloc label.MuiFormLabel-filled {
    transform: translate(14px, -9px) scale(0.75);
  }
  
  .e5wifih-metrics-process .filterbloc .MuiInputBase-input{
    padding: 8.5px 14px;
  }