.e5h-incident-list .scoreimg {
  height: 15px;
  width: 15px;
}

.e5h-incident-list .critslider {
  width: calc(100% - 100px);
  padding: 0 50px 0 10px;
}

.e5h-incident-list .filtertitle {
  width: 100px;
}

.e5h-incident-list .verticalcenter {
  height: 22px;
  padding-top: 5px;
}

.e5h-incident-list .small-hint {
  font-size: 0.9em;
}

.e5h-incident-list-popover {
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
}

.e5h-incident-list .toggle {
  display: flex;
  background: #F9FCFF;
  border: 1px solid rgba(0, 135, 184, 0.5);
  border-radius: 15px;
}

.e5h-incident-list .toggle .toggle-item {
  padding: 5px 10px;
  background: #F9FCFF;
  border-radius: 15px;
  color: rgba(0, 135, 184, 0.5);
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
}

.e5h-incident-list .toggle .active {
  background: #0080FF;
  color: white;
}

.e5h-incident-list .filters {
  justify-content: flex-end;
}

.e5h-incident-list .filters .filter-icon {
  height: 30px;
  width: auto;
}

.incident-list {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.heatmap-container {
  position: relative;
}