.e5bullet-gauge {
  min-width: 295px;
}

.e5bullet-gauge .e5gauge-labelvalue {
  align-self: center;
  padding-top: 5px;
  font-size: 0.95em;
}

.e5bullet-gauge .e5gauge-value {
  font-size: 1.75em;
}

.e5bullet-gauge .e5gauge-info {
  text-decoration: underline;
}
