.rolesHeader {
    color: #0080FF;
    font-weight: bold;
}

.statusHeader {
    color: #0080FF;
    font-weight: bold;
}

.roles {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
    border-right: 2px solid #0080FF;
}

.updateButton {
    color: #2BA217 !important;
    background-color: rgba(43, 162, 23, 0.10) !important;
    cursor: pointer;
    border-radius: 8px !important;
}

.closeButton {
    color: #0080FF !important;
    background-color: rgba(0, 135, 184, 0.1) !important;
    cursor: pointer;
    border-radius: 8px !important;
}

.buttonsWrapper {
    display: flex;
    padding: 10px;
    justify-content: space-between;
}

.roles_question {
    font-weight: bold;
    border: 1px solid black;
    display: inline-flex;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    text-align: center;
    position: relative;
    /* top: -.5em; */
    padding: 0px 5px;
    border-color: #0080FF;
    color: #0080FF;

}

.bp3-control.bp3-checkbox input:checked~.bp3-control-indicator::before {
    /* background: radial-gradient(ellipse at center, #f73134 0%, #ff0000 37%, #ff0000 37%, #23bc2b 37%, #23bc2b 37%) */
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' %3E%3Ccircle cx='8' cy='8' r='4' stroke-width='3' fill='white' /%3E%3C/svg%3E");
}