.usernameWrapper {
    font-weight: bold;
    padding: 10px;
}

.userFullname {
    margin-bottom: 10px;
}

.removeButton {
    color: #F00 !important;
    background: rgba(255, 0, 0, 0.10) !important;
    cursor: pointer;
    border-radius: 8px !important;
}

.closeButton {
    color: #0080FF !important;
    background-color: rgba(0, 135, 184, 0.1) !important;
    cursor: pointer;
    border-radius: 8px !important;
}

.buttonsWrapper {
    display: flex;
    padding: 10px;
    padding-bottom: 10px;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #fafcff;
    border-radius: 0 0 6px 6px;
}

.remove_acl_modal {
    padding-right: 10px;
    padding-bottom: 0
}