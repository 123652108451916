.e5wifih-nodeplus {
  font-size: 0.9em;
}

.e5wifih-nodeplus .rdmcmdttlid {
  width: 101px;
}

.e5wifih-nodeplus .rdmcmdcomment {
  width: 220px;
}

.e5wifih-nodeplus .e5admin-rdm-req-legend .bp3-popover-wrapper {
  margin-top: 0;
}

.e5wifih-nodeplus .rdmstatus {
  align-self: center;
}

.e5wifih-nodeplus .rdmstatus.none {
  font-style: italic;
}

.e5wifih-nodeplus .lastcmdparent {
  display: grid;
  grid-template-columns: 100%;
}

.e5wifih-nodeplus .lastcmdparent:first-child {
  margin-top: 5px;
}

.e5wifih-nodeplus .lastcmdparent:not(:first-child) {
  border-top: solid 1px #A0A0A0;
}

.e5wifih-nodeplus .lastcmdparent .lastcmd {
  display: grid;
  grid-template-columns: 5% 5% 20% 24% 13% 20% 13%;
}

.e5wifih-nodeplus .lastcmdparent .lastcmd:hover {
  background-color: #E5F2FF;
}

.e5wifih-nodeplus .lastcmdparent .lastcmd .lastcmdfield {
  padding: 5px;
  overflow: hidden;
  white-space: nowrap;
}

.e5wifih-nodeplus .lastcmdparent .lastcmd .lastcmdfield:not(:first-child) {
  border-left: solid 1px #A0A0A0;
}

.e5wifih-nodeplus .lastcmdparent .lastcmd .lastcmdfield .e5wifih-nodeplus-statusimgdiv {
  display: inline-block;
  padding: 2px 0 0 2px;
}

.e5wifih-nodeplus .lastcmdparent .lastcmd .lastcmdfield .e5wifih-nodeplus-statusimgdiv img {
  height: 15px;
}

.e5wifih-nodeplus .interf.down {
  background-color: #E4E4E4;
}

.e5wifih-nodeplus .interf .value {
  font-weight: bold;
  font-size: 1.1em;
}

.e5wifih-nodeplus .interf .inline {
  align-self: center;
}

.e5wifih-nodeplus .interf .datalist {
  padding-top: 5px;
}

.e5wifih-nodeplus .interf .datalist .subcat {
  text-decoration: underline;
}

.e5wifih-nodeplus .interf .dot {
  padding: 0 10px;
  font-size: 2em;
  line-height: 0.5em;
}

.e5wifih-rdm-fullresponsedial {
  overflow: auto;
}

.e5wifih-rdm-fullresponsedial .e5wifih-rdm-fullresponsecontent {
  padding: 0 10px 0 0;
}

.e5wifih-nodeplus-statusimgdivtip {
  background-color: #FFFFFF;
  border-radius: 15px;
  display: inline-block;
  padding: 5px 0 0 5px;
  width: 30px;
  height: 30px;
}

.e5wifih-nodeplus-statusimgdivtip img {
  height: 20px;
}
