.e5treeview {
  padding: 3px 0 3px 0;
}

.e5treeview > * {
  background-color: #FFFFFF;
}

.e5treeview .bp3-icon.e5symbol {
  color: #C0C0C0;
  padding: 0 0 0 10px;
}

.e5treeview .bp3-icon.e5action {
  cursor: pointer;
}

.e5treeview .bp3-icon.e5action:hover {
  background-color: #C0C0C0;
}

.e5treeview .bp3-icon.e5dot {
  color: #C0C0C0;
  padding: 0 5px 0 0;
}

.e5treeview .e5treeview-listitem, .e5treeview .e5treeview-objitem {
  padding: 0 0 0 20px;
}

.e5treeview .e5treeview-label {
  display: inline-block;
}

.e5treeview .e5treeview-value {
  display: inline-block;
  font-weight: bold;
}
