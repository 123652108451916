.e5text-indic {
  border: solid 1px #A0A0A0;
  padding: 5px;
  width: 200px;
}

.e5cb-dashboard .e5text-indic {
  width: 33%;
}

.e5cb-dashboard .e5text-indic .bp3-popover-target {
  width: auto;
}

.e5text-indic .conten {
  align-items: center;
  height: 34px;
}

.e5text-indic img {
  height: 39px;
  width: 39px;
}

.e5text-indic .bp3-popover-target {
  width: 200px;
}

.e5text-indic .content .label-container {
  /* align-items: center; */
  margin: 0 auto;
}


.e5text-indic .content .value-unit {
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
}

.e5text-indic .content .label {
  display: inline-block;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}

.e5text-indic .content .value {
  font-weight: bold;
  font-size: 1.1em;
}

.e5text-indic.small {
  padding: 3px 8px 3px 8px;
}

.e5text-indic.small .content {
  font-size: 0.8em;
  align-items: center;
}