.e5pie-chart .clickable-pie svg {
  cursor: pointer;
}


.e5pie-chart{
  width: 100%;
}
.no-data-pie{
  height: 100%;
  width: 100%;
}

.echarts-for-react {
  width: 100%;
  height: 350px !important;
}

.no-data{
  color: #595959;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
