.e5loginpage {
  position: relative;
  overflow: hidden;
  background: #F9FCFF;
  padding: 0 2vh !important;
}


.e5loginpage .content{
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
}

.e5loginpage .login-icon{
  height: 50vh;
  width: auto;
}
.e5loginpage .loginback {
  position: absolute;
  z-index: 0;
}

.login-input{
  margin-bottom: 2vh !important;
  width: 41.5vh;
  border-radius: 15px;
  font-size: 1.6vh !important;
}

.login-input input{
  height: 3vh !important;
  padding: 0.8vh 1.4vh;
}

.login-input label{
  font-size: 1.6vh !important;
}

.e5loginpage .e5loginform {
  width: 40%;
  z-index: 1;
}

.e5loginpage .e5loginform .loginlogo {
  width: 250px;
}

.e5loginpage .e5loginform .welcomemessage {
  display: flex;
  flex-direction: column;
  color: #0080FF;
  font-weight: 700;
}

.e5loginpage .e5loginform .welcomemessage .title{
  font-size: 6.2vh;
}

.e5loginpage .e5loginform .welcomemessage .subtitle{
  font-size: 3.2vh;
  margin-bottom: 6vh !important;
}

.x-mark{
  height: 50px;
  width: 50px;
  align-self: center;
  margin-bottom: 20px;
}

.e5loginpage .e5loginform .button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0080FF !important;
  text-align: center;
  font-size: 2vh;
  font-weight: 500;
  border-radius: 10px;
  background: rgba(0, 128, 255, 0.10);
  width: 14.5vh;
  height: 5vh;
  margin-left: 13.5vh;
}

.modal-content{
  display: flex;
  flex-direction: column;
  color:black;
  font-size: 20px;
  font-weight: 500;
  border-radius: 10px;
  background: #F9FCFF;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  width: 460px;
  padding: 15px;
  gap: 12px;
}

.cancel-button{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0080FF !important;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  border-radius: 10px;
  background: rgba(0, 128, 255, 0.10) !important;
  width: 145px;
  height: 50px;
  margin-left: 135px;
  align-self: flex-end;
  border:none !important;
  box-shadow: none !important;
}

.e5loginpage .e5loginform .e5logincopyright {
  font-size: 0.8em;
  font-style: italic;
}