.e5cb-wifi {
  height: 100px;
  padding-right: 20px;
}

.e5cb-wifi>div:not(:first-child)>div>div {
  height: 384px;
  margin-bottom: 20px;
}

.e5cb-wifi>div:not(:first-child)>div>div.e5angular-gauge>div {
  align-items: center;
}