.profile-tabs .bp3-tab-list {
    padding: 0;
    border-image-slice: 1;
    border-bottom: none !important;
    background-color: white;
    color: #222221;
    box-shadow: 0px 6px 12px 0px #B8B8B814;
    margin: 0;
    line-height: 0;
    font-size: 14px !important;
    width: 284px;
    height: max-content;
    border-radius: 10px;
}

.profile-tabs .bp3-tab[aria-selected="true"] {
    color: #222221 !important;
    font-weight: 700;
}

.profile-tabs .bp3-tab:hover {
    color: #222221 !important;
}

.profile-tabs .bp3-tab-list div {
    padding: 16px 24px 16px 24px !important;
}

.profile-tabs .bp3-tab-list div:not(:last-child) {
    border-bottom: 1px solid #E7EAEE
}

.profile-tabs .bp3-tab-indicator-wrapper {
    display: none;
}

.profile-toast {
    z-index: 2000;
}
