.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table {
  display: grid;
  grid-template-columns: 265px repeat(3, 81px);
}

.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div>div:not(:first-child) {
  background-color: white;
  font-weight: 500;
  font-size: 14px;
}


.e5wifih-metrics-leftcolumn {
  display: none;
}

.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div:first-child>div:first-child {
  border-top-left-radius: 10px;
}

.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div:last-child>div:first-child {
  border-top-right-radius: 10px;
}

.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div:first-child>div:last-child {
  border-bottom-left-radius: 10px;
}

.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div:last-child>div:last-child {
  border-bottom-right-radius: 10px;
}

.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div>div {
  border-left: solid 2px #F3F5F8;
}


.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div:last-child>div {
  border-right: solid 2px #F3F5F8;
}

.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div>div:last-child {
  border-bottom: solid 2px #F3F5F8;
}


.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div>div {
  height: 58px;
  padding: 0 5px;
  display: flex;
  align-items: center;
}

.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div:not(:first-child)>div {
  justify-content: center;
}

.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div>div:first-child {
  background-color: #EAEDF1;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  justify-content: center;
}

.e5h-metric-sys-equip .e5wifih-metrics-nodes-summary-table>div>div:not(:first-child) {
  border-top: solid 2px #F3F5F8;
}

.e5h-metric-sys-equip option[disabled] {
  font-size: 0.9em;
  font-weight: bold;
}

.e5h-metric-sys-equip .e5pie-chart {
  width: 33%;
}

.load1 {
  padding: 8px;
}

.e5wifih-metrics-overview .e5pie-chart {
  width: 100%;
}

.e5wifih-metrics-overview .filterbloc label {
  transform: translate(14px, 9px) scale(1);
}

.e5wifih-metrics-overview .filterbloc label.Mui-focused {
  transform: translate(14px, -9px) scale(0.75);
}

.e5wifih-metrics-overview .filterbloc label.MuiFormLabel-filled {
  transform: translate(14px, -9px) scale(0.75);
}

.e5wifih-metrics-overview .filterbloc .MuiInputBase-input {
  padding: 8.5px 14px;
}

.e5compo h3 {
  font-size: 16px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  height: 20px;
}

.e5compo .wifih-metrics-ovrview-subheader {
  text-align: center;
  margin: 0 auto;
  font-weight: bold;
}

.e5h-metric-sys-equip-title {
  font-size: 16px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wifih-metrics-chart-title {
  text-align: center;
  margin: 0 auto;
  font-weight: bold;
}