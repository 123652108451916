.e5admin-acl-list .e5admin-acl-titleline {
  align-items: center;
  height: 30px;
  font-weight: 600;
  font-size: 15px;
}

.e5admin-acl-list .MuiFormControl-root {
  height: 40px;
  width: 200px;
}

.e5admin-acl-list .MuiInputBase-root {
  height: 40px;
  border-radius: 12px;
}

.e5admin-acl-list .e5admin-acl-titleline .e5compotitle {
  color: #0080FF;
  font-weight: 600;
  font-size: 15px;
  width: unset;
  margin-bottom: 15px;
}

.e5admin-acl-list .e5admin-acl-titleline .button {
  background: rgba(0, 135, 184, 0.1);
  border-radius: 10px;
  color: #0080FF;
  font-weight: 500;
  font-size: 14px;
  width: 150px;
  border: none;
  box-shadow: none;
  padding: 5px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.e5admin-acl-list .e5admin-acl-titleline .bp3-icon {
  color: #0080FF;
}


.e5admin-acl-filterbloc {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.e5admin-acl-filterbloc input {
  padding: 8.5px 14px !important;
}

.e5admin-acl-filterbloc label {
  transform: translate(14px, 9px) scale(1);
}

.e5admin-acl-filterbloc label.Mui-focused {
  transform: translate(14px, -9px) scale(0.75);
}

.e5admin-acl-filterbloc label.MuiFormLabel-filled {
  transform: translate(14px, -9px) scale(0.75);
}

.e5admin-acl-filterbloc .label {
  font-size: 13px;
  position: absolute;
  background-color: #F9FCFF;
  z-index: 2;
  color: #0080FF;
  line-height: 13px;
  left: 10px;
  transform: translateY(-50%);
}

.e5admin-acl-list .e5admin-acl-filterline {
  align-items: flex-end;
}

.e5admin-acl-list .e5admin-acl-filterbloc .label {
  font-size: 0.8em;
}

.e5admin-acl-list .e5admin-acl-filterbloc.hasfilter {
  border: solid 1px #AA0000;
}

.e5admin-acl-list .e5admin-acl-filterbloc .filterdate input {
  width: 150px;
  text-align: center;
}

.e5admin-acl-list .e5admin-acl-acltableouter {
  overflow: visible;
}

.e5admin-acl-list .e5admin-acl-acltableouter .e5admin-acl-acltableinner {
  width: 100px;
}

.e5admin-acl-list .e5admin-acl-acltableouter .e5admin-acl-acltableinner .e5admin-acl-acltable {
  border: solid 1px #A0A0A0;
}

.e5admin-acl-list .e5admin-acl-acltableouter .e5admin-acl-acltableinner .e5admin-acl-acltable .tbheadname {
  font-size: 0.8em;
  display: inline-block;
  padding-top: 5px;
}

.e5admin-acl-list .e5admin-acl-acltableouter .e5admin-acl-acltableinner .e5admin-acl-acltable .tbhead {
  text-align: center;
}

.e5admin-acl-list .e5admin-acl-acltableouter .e5admin-acl-acltableinner .e5admin-acl-acltable .aclactioncell {
  background-color: #ffffff;
  margin-top: 6px;
}

.e5admin-acl-delete-confirm {
  padding: 10px;
  width: 500px;
}

textarea.bp3-input {
  width: 100%;
  resize: none;
  height: 320px;
}

.e5admin-acl-header {
  border-image-slice: 1;
  border-bottom: 2px solid !important;
  border-image-source: linear-gradient(90deg, #0080FF 0%, #00D6D4 99.92%);
  padding-bottom: 15px !important;
  background-color: #fafcff !important;
}

.admin-acl-save-button {
  color: #2BA217 !important;
  margin-left: 0;
}



.admin-acl-button-wrapper {
  display: flex;
  justify-content: space-between;
}

.admin-acl-footer-left {
  display: flex;
}

.bp3-dialog.acl-popup {
  padding-bottom: 10px;

}

.bp3-dialog.acl-popup .bp3-dialog-body {
  margin-left: 10px;
  margin-right: 10px;
}

.deleteAclGroupsButton {
  color: #F00;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background: rgba(255, 0, 0, 0.10);
  width: 196px;
  height: 35px;
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  padding-left: 5px;
  box-sizing: content-box;
}

.e5admin-acl-titleline .delete-filters-button {
  background: rgba(0, 135, 184, 0.1) !important;
  border-radius: 10px;
  color: #0080FF !important;
  font-weight: 500;
  font-size: 14px;
  width: 150px;
  min-width: 150px;
  border: none;
  box-shadow: none !important;
  padding: 5px 0;
  margin-bottom: 4px !important;
}