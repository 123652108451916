.e5wifi-incident-button {
  background: #FFFFFF;
  padding: 5px 5px 1px 5px;
  border: solid 1px #A0A0A0;
  border-radius: 5px;
  min-height: 60px;
}

.e5wifi-incident-button:hover {
  opacity: 0.8;
}

.e5wifi-incident-button.selected {
  border-color: #E5F2FF;
  background-color: #E5F2FF;
  box-shadow: 0 0 0 3px #0059b3;
}

.e5wifi-incident-button .prehaul {
  box-shadow: 0 0 0 1px #A0A0A0;
  border-radius: 4px;
  padding: 2px 5px;
}

.e5wifi-incident-button .haul {
  background-color: #D0D0D0;
  border-radius: 4px;
  padding: 2px 5px;
}

.e5wifi-incident-button.clickable {
  cursor: pointer;
  font-size: 0.9em;
}

.e5wifi-incident-button.clickable .incidentcolumn {
  border-left: solid 1px #909090;
  padding: 0 5px 0 5px;
  overflow-wrap: break-word;
}

.e5wifi-incident-button.clickable .prehaul, .e5wifi-incident-button.clickable .haul, .e5wifi-incident-button.clickable .elem {
  align-self: center;
}

.e5wifi-incident-button.clickable .value {
  font-weight: bold;
}

.e5wifi-incident-button.clickable > div:not(.expand) {
  margin-bottom: 5px;
}

.e5wifi-incident-button.clickable .elem {
  border-radius: 4px;
  padding: 2px 5px;
  margin: 0 5px 5px 0;
  display: inline-block;
  white-space: nowrap;
}

.e5wifi-incident-button.clickable .elem:not(.dev) {
  background-color: #D0D0D0;
}

.e5wifi-incident-button.clickable .elem.dev {
  border: solid 1px #909090;
}

.e5wifi-incident-button .recom-line {
  align-items: center;
}

.e5wifi-incident-button .context,
.e5wifi-incident-button .recom-desc {
  font-weight: bold;
}

.e5wifi-incident-button-outer {
  position: relative;
}

.e5wifi-incident-button-outer:last-child .fixed2right {
  top: -380px;
}

.e5wifi-incident-button-outer:nth-last-child(2) .fixed2right {
  top: -260px;
}

.e5wifi-incident-button-outer:nth-child(2) .fixed2right {
  top: -31px;
}

.e5wifi-incident-button-outer:nth-child(3) .fixed2right {
  top: -151px;
}

.e5wifi-incident-button-outer .fixed2right {
  position: absolute;
  right: -634px;
  width: 624px;
  top: -195px;
}

.e5wifi-incident-button-outer .fixed2right > div {
  box-shadow: 0 0 0 3px #0059b3;
  border-radius: 5px;
}

.e5wifih-incident-legend {
  max-width: 400px;
  padding: 10px;
}
