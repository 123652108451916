.circle-validation {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 10px;
}

.invalid {
    background-color: #767675;
}

.valid {
    background-color: #008000;
}

input[type="checkbox"] {
    display: none;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}