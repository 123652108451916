.e5wifih-filters {
  align-items: center;
  min-height: 50px;
}

.e5wifih-filters {
  align-items: center;
  background-color: transparent !important;
  padding: 5px;
}

.e5wifih-filters span {
  color: #0080FF;
}

.e5wifih-filters .bp3-html-select select {
  background-color: transparent;
  border-bottom: 2px solid #0080FF;
  box-shadow: none;
  border-radius: 0;
}

.e5wifih-filters input {
  background-color: transparent;
  border-bottom: 2px solid #0080FF;
  box-shadow: none;
  border-radius: 0 !important;
}

.household-dashboard-date-header {
  color: #0080FF;
  margin-left: 7px !important;
}