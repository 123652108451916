.e5wifiheatmap {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0 20px 20px 20px;
  overflow: auto;
}

.e5wifiheatmap .echarts-for-react {
  height: 95% !important;
}

.e5wifiheatmap .legend,
.e5wifiheatmap .legend .legend-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.e5wifiheatmap .legend p,
.e5wifiheatmap .legend .legend-item p {
  margin: 0;
}

.e5wifiheatmap .legend .legend-item .block {
  content: '';
  border-radius: 5px;
  width: 25px;
  height: 15px;
  margin-left: 10px;
  margin-right: 10px;
}

.e5wifiheatmap .legend .legend-item .rssi-red {
  background-color: #ff0000;
}

.e5wifiheatmap .legend .legend-item .rssi-orange {
  background-color: #d9822b;
}

.e5wifiheatmap .legend .legend-item .rssi-gray {
  background-color: #5c7080;
}

.e5wifiheatmap .legend .legend-item .rssi-green {
  background-color: #15b371;
}