.scroll-less-wide .e5wifih-health-nodes.minimal {
  width: 225px;
}

.e5wifih-health-nodes.minimal {
  font-size: 0.9em;
  width: 240px;
}

.e5wifih-health-nodes .clickable {
  background: #FFFFFF;
  padding: 5px;
  border: solid 1px #A0A0A0;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.e5wifih-health-nodes .clickable:hover {
  opacity: 0.8;
}

.e5wifih-health-nodes .clickable.selected {
  border-color: #E5F2FF;
  background-color: #E5F2FF;
  box-shadow: 0 0 0 3px #0059b3;
}

.e5wifih-health-nodes .clickable .iconhealth {
  align-items: center;
}

.e5wifih-health-nodes .clickable .img {
  height: 30px;
  min-height: 30px;
  width: 30px;
  min-width: 30px;
}

.e5wifih-health-nodes .clickable .scorediv {
  text-align: center;
}

.e5wifih-health-nodes .clickable .scorediv .scorewifi {
  padding: 2px 0;
  font-size: 0.9em;
  font-weight: bold;
  border-radius: 3px;
}

.e5wifih-health-nodes .clickable .scorediv .scorevalue {
  width: 40px;
}

.e5wifih-health-nodes .clickable .scoreimg {
  height: 15px;
  width: 15px;
  min-width: 15px;
}

.e5wifih-health-nodes .clickable .texts {
  display: grid;
  grid-template-columns: auto auto auto;
  font-size: 0.9em;
}

.e5wifih-health-nodes .clickable .texts > div {
  overflow: hidden;
  border-left: solid 1px #909090;
  padding: 0 5px 0 5px;
  overflow-wrap: break-word;
}

.e5wifih-health-nodes .clickable .texts .value {
  font-weight: bold;
  font-size: 1.1em;
}
