.e5welcome-page {
  overflow-y: auto;
  align-items: center;
}

.e5welcome-page:first-child {
  padding-top: 20px;
}

.e5welcome-page .page-wrapper{
  width: 100%;
  align-items: center;
}

.e5welcome-page .card-row{
  gap: 30px;
  padding-inline:20px;
  margin-top: 40px;
}

.help-icon{
  width: 40%;
  max-width: 500px;
  height: auto;
  margin-right: 75px !important;
  margin-left: 50px !important;
}


.e5welcome-page .text-container{
  width: 60%;
  align-self: center;
  margin-right: 50px !important;
}

.e5welcome-page .button {
  background: rgba(0, 135, 184, 0.1);
  border-radius: 10px;
  box-shadow: none;
  padding: 12px 15px;
  color:#0080FF !important;
  font-weight: 700;
  font-size: 14px;
}

.e5welcome-page .button.bt-margin {
  margin: 0 15px 0 15px;
}

.e5welcome-page .top-container {
  margin-bottom: 50px !important;
}

.e5welcome-page .e5welcomecompo {
  color: #000000;
  margin-bottom: 50px;
}

.e5welcome-page .e5welcomecompo .title {
  font-size: 1.3em;
  font-weight: bold;
  color: #0080FF;
}

.e5welcome-page .e5welcomecompo .subtitle {
  color: #0080FF;
  font-size: 20px;
  font-weight: 700;
}

.e5welcome-page .e5welcomecompo .subtitle.info {
  margin: 0 25px 10px 0;
  font-size: 18px;
  font-weight: 700;
}
.e5welcome-page .e5welcomecompo .subtitle.wifi {
  font-size: 0.9em;
}
.e5welcome-page .e5welcomecompo .subtext {
  font-size: 14px;
  font-weight: 500;
  margin: 0 25px 0 0;
  height: 90px;
  overflow: hidden;
}
.e5welcome-page .e5welcomecompo .subtext.users {
  font-size: 0.89em;
}
.e5welcome-page .e5welcomecompo .subimg {
  margin-bottom: 20px;
}

.e5welcome-page .e5welcomecompo .description {
  font-size: 14px;
  font-weight: 500;
}

.e5welcome-page .e5welcomecompo .roundimg {
  background-color: #BFF1FF;
  border-radius: 100%;
}

.e5welcome-page .e5welcomecompo .roundimg.sizedimg {
  width: 100px;
  height: 100px;
  float: left;
  margin: 0 20px 0 10px
}

.e5welcome-page .e5welcomecompo .card {
  background-color: white;
  border-radius: 30px;
  padding: 20px;
  height: 325px;
  text-align: center;
}
.e5welcome-page .e5welcomecompo .card.info {
  padding: 0;
  width: 375px;
  height: 150px;
}

.e5welcome-page .e5welcomecompo .card.small {
  height: 225px;
}

.e5welcome-page .e5welcomecompo .center {
  text-align: center;
  font-size: 20px;
  margin: 10px 0 20px 0;
}

.e5welcome-page .e5welcomecompo .verticalcenter {
  align-items: center;
}

.borderedbox{
  width: 300px;
}