.redButton {
    color: #F00;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    background: rgba(255, 0, 0, 0.10);
    height: 35px;
    /* margin-left: 15px; */
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.greenButton {
    color: #2BA217;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 10px;
    background: rgba(43, 162, 23, 0.10);
    height: 35px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.blueButton {
    background: rgba(0, 135, 184, 0.1);
    display: inline-flex;
    border-radius: 10px;
    color: #0080FF;
    font-weight: 500;
    font-size: 14px;
    border: none;
    box-shadow: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    height: 35px;
}