.e5selectdate {
  width: 83px;
}

.e5selectdatedates {}

.e5selectdatedates select {
  text-align: center;
  width: 83px !important;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #0080FF;
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  height: 30px;
}