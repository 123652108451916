.e5searchni {
  align-items: center;
}

.e5searchniinput {
  width: 360px !important;
}

.e5searchniauto {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}

.e5searchniauto .e5searchniautoclose {
  padding: 2px 2px 0 0;
}

.e5searchniauto .e5searchniautoclose:hover {
  background-color: #E5E9ED;
  cursor: pointer;
}