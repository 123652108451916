.e5angular-gauge {
  min-width: 310px;
}

.e5angular-gauge:not(.small) {
  min-height: 250px;
}

.e5angular-gauge .e5gauge-labelvalue,
.e5angular-gauge .e5gauge-label {
  align-self: center;
}

.e5angular-gauge .e5gauge-value {
  font-size: 3em;
}

.e5angular-gauge .e5gauge-value.small {
  font-size: 2.5em;
}

.canvas .echarts-for-react {
  height: 100px !important;
}

.canvas .echarts-for-react div {
  height: 100px !important;
}

.canvas-small .echarts-for-react {
  height: 75px !important;

}

.canvas-small-30 .echarts-for-react {
  height: 75px !important;

}

.canvas-small .echarts-for-react div {
  height: 75px !important;
}

.canvas-small-30 .echarts-for-react div {
  height: 75px !important;
}

.list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  align-items: center;
}

.e5cb-dashboard .list .canvas-small {
  width: 50%;
}

.e5cb-dashboard .list .canvas-small-30 {
  width: 50%;
}

.list .canvas-small {
  width: 100%;
}

.list .canvas-small-30 {
  width: 100%;
}

.e5cb-dashboard .list .canvas-small-30 {
  width: 30%;
}

.list .canvas-small-30 {
  /* width: 100%; */
  width: 550px;
  /* overflow: hidden; */
}

.overview-progress-chart {
  min-width: 490px;
}

.system-progress-chart {
  min-width: 726px !important;
  margin-left: -69px !important;
}

.eqipment-progress-chart {
  min-width: 750px !important;
}

.system-list-progress-charts {
  min-width: 1080px;
  overflow: hidden;
}

.equipment-list-progress-charts {
  overflow: hidden;
}

.overview-progress-wrapper {
  min-width: 500px;
}