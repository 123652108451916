.e5cb-sys-overview {
  height: 100px;
  padding-right: 20px;
}

.e5cb-sys-overview > div > div > div {
  height: 384px;
}

.e5cb-sys-overview > div > div > div.e5angular-gauge > div,
.e5cb-sys-overview > div > div > div.e5radar-chart > div {
  align-items: center;
}

.e5cb-sys-overview .e5pie-chart {
  height: 384px;
}
