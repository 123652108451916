.e5wifitopology2 {
  position: relative;
  width: 100%;
  height: 100%;
}

.e5wifitopology2 .e5wifitopo2inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 90%;
  height: 90%;
  margin-top: 40px;
}

.e5wifitopology2 .e5wifitopo2controls {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 30px;
}

.e5wifitopology2 .e5wifitopo2controls .controlsdesc {
  white-space: nowrap;
  align-self: center;
}

.e5wifitopology2 .e5wifitopo2controls .bp3-button-group {
  margin: 0 5px;
}

.bp3-button.bp3-intent-primary {
  background-color: #0080FF;
  color: white !important;
  box-shadow: none;
}

.e5wifitopology2 .e5wifitopo2controls .bp3-button-group>span {
  margin-right: 5px;
}

.e5wifitopology2 .e5wifitopo2controls .bp3-button-group button {
  border: 1px solid #0080FF !important;
  border-radius: 5px !important;
  color: #0080FF;
  font-weight: 500;
  font-size: 14px;
}

.e5wifitopology2 .e5wifitopo2controls .bp3-slider {
  margin: 5px 10px 0 8px;
  margin-right: 31px !important;
}

.e5wifitopology2 .e5wifitopo2controls .label2right .bp3-slider-label:not(:only-child),
.e5wifitopology2 .e5wifitopo2controls .label2left .bp3-slider-label {
  /*transform: translate(calc(-100% + 10px), -20px);*/
}

.e5wifitopology2 .e5wifitopo2controls .label2right .bp3-slider-label:only-child {
  /*transform: translate(-10px, -20px);*/
}

.e5wifitopology2 .e5wifitopo2controls .label2right .bp3-active .bp3-slider-label:not(:only-child),
.e5wifitopology2 .e5wifitopo2controls .label2left .bp3-active .bp3-slider-label {
  /*transform: translate(calc(-100% + 10px), -29px);*/
}

.e5wifitopology2 .e5wifitopo2controls .label2right .bp3-active .bp3-slider-label:only-child {
  /*transform: translate(-10px, -29px);*/
}

.e5wifitopology2 .e5wifitopo2controls .bp3-slider-axis .bp3-slider-label:not(:last-child) {
  display: none;
}

.e5wifitopology2 .e5wifitopo2controls .bp3-slider-axis .bp3-slider-label,
.e5wifitopology2 .e5wifitopo2controls .bp3-slider-handle .bp3-slider-label {
  white-space: nowrap;
}

.e5wifitopology2 .e5wifitopo2controls .bp3-active .bp3-slider-label {
  font-size: 1.5em;
}

.e5wifitopology2 .e5wifitopo2controls .bp3-slider-track {
  background: #0080FF;
}

.e5wifitopology2 .e5wifitopo2controls .bp3-slider-handle {
  background: #0080FF;
}

.e5wifitopology2 .legend {
  font-size: 0.9em;
  margin: 40px 0 0 10px;
  margin-left: 0;
  padding: 10px 5px;
  background: #FFFFFF;
  border: 1px solid #0080FF;
  border-radius: 10px;
  position: fixed;
  z-index: 1;
}

.e5wifitopology2 .legend img {
  height: 13px;
  width: 28px;
  padding-top: 3px;
}

.e5wifitopology2 .legend .legendrssi {
  width: 26px;
  height: 13px;
  border-radius: 5px;
}

.e5wifitopology2 .legend .legendrssi.rssired {
  background-color: #FF0000;
}

.e5wifitopology2 .legend .legendrssi.rssiorange {
  background-color: #FFA726;
}

.e5wifitopology2 .legend .legendrssi.rssigray {
  background-color: #D4E157;
}

.e5wifitopology2 .legend .legendrssi.rssigreen {
  background-color: #43A047;
}