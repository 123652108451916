* {
  font-family: "Montserrat", sans-serif;
}

.e5admin-rdm-list .e5admin-rdm-titleline {
  align-items: center;
  height: 30px;
  font-weight: 600;
  font-size: 15px;
}

.e5admin-rdm-list .e5admin-rdm-titleline .e5compotitle {
  color: #0080FF;
  font-weight: 600;
  font-size: 15px;
  width: unset;
}

.e5admin-rdm-list .e5admin-rdm-titleline button {
  background: rgba(0, 135, 184, 0.1);
  border-radius: 10px;
  color: #0080FF;
  font-weight: 500;
  font-size: 14px;
  width: 150px;
  border: none;
  box-shadow: none;
  padding: 5px 0;
}

.e5admin-rdm-list .e5admin-rdm-titleline .bp3-icon {
  color: #0080FF;
}

.e5admin-rdm-list .e5admin-rdm-titleline .e5admin-rdm-req-legend {
  margin-left: auto !important;
}

.filterblock-date {
  align-self: start;
}

.e5admin-rdm-filterinput label {
  top: 2px;
}

.e5admin-rdm-filterbloc label {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  vertical-align: middle;
}

.e5admin-rmd-filter-main-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
}

.e5admin-rmd-filter-main-subtext {
  font-size: 11px;
  font-weight: 500;
  line-height: 13.41px;
  text-align: left;
}

.e5admin-rmd-filtericon-popover {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.e5admin-rmd-filtericon-popover span {
  text-align: left;
}

.e5admin-rdm-filterbloc select {
  background: #FFFFFF;
  border: 1px solid #0080FF;
  border-radius: 10px;
  color: #0080FF;
}

.e5admin-rdm-list .e5admin-rdm-filterline .bp3-icon {
  color: #0080FF;
}

.e5admin-rdm-list .e5admin-rdm-filterline .e5admin-rdm-req-legend {
  margin-left: auto !important;
}


.e5admin-rdm-list .e5admin-rdm-filterline button {
  background: rgba(0, 135, 184, 0.1);
  border-radius: 10px;
  color: #0080FF;
  font-weight: 500;
  font-size: 14px;
  border: none;
  box-shadow: none;
}

.e5admin-rdm-filterbloc .label {
  font-size: 13px;
  position: absolute;
  background-color: #F9FCFF;
  z-index: 2;
  color: #0080FF;
  line-height: 13px;
  left: 10px;
  transform: translateY(-50%);
}

.e5admin-rdm-list .header {
  background-color: #F9FCFF;
  padding-bottom: 15px;
  border-image-slice: 1;
  border-bottom: 2px solid !important;
  border-image-source: linear-gradient(90deg, #0080FF 0%, #00D6D4 99.92%);
  margin-bottom: 15px;
}

.e5admin-rdm-list .e5admin-rdm-filterline {
  align-items: center;
  margin-top: 15px !important;
}

.e5admin-rdm-list .e5admin-rdm-filterbloc .label {
  font-size: 0.8em;
}

.e5admin-rdm-list .e5admin-rdm-filterbloc.hasfilter {
  border: solid 1px #AA0000;
}

.e5admin-rdm-list .e5admin-rdm-filterbloc .filterdate input {
  width: 110px;
  text-align: center;
}

.e5admin-rdm-list .e5admin-rdm-paging {
  padding-top: 15px;
  border-top: 2px solid #0080FF;
  height: 24px;
}

.e5admin-rdm-list .e5admin-rdm-paging .bp3-button {
  background: #F9FCFF;
  border: 1px solid #0080FF;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #0080FF;
  box-shadow: none;
}


.e5admin-rdm-list .e5admin-rdm-paging .bp3-icon {
  color: #0080FF;
}

.e5admin-rdm-list .e5admin-rdm-rdmtableouter {
  overflow: visible;
}

.e5admin-rdm-list .e5admin-rdm-rdmtableouter .e5admin-rdm-rdmtableinner .e5admin-rdm-rdmtable {
  border: solid 1px #A0A0A0;
}

.e5admin-rdm-list .e5admin-rdm-rdmtableouter .e5admin-rdm-rdmtableinner .e5admin-rdm-rdmtable .tbheadname {
  font-size: 0.8em;
  display: inline-block;
  padding-top: 5px;
}

.e5admin-rdm-list .e5admin-rdm-rdmtableouter .e5admin-rdm-rdmtableinner .e5admin-rdm-rdmtable .tbhead {
  text-align: center;
}

.e5admin-rdm-list .e5admin-rdm-rdmtableouter .e5admin-rdm-rdmtableinner .e5admin-rdm-rdmtable .statusimgdiv {
  text-align: center;
}

.e5admin-rdm-list .e5admin-rdm-rdmtableouter .e5admin-rdm-rdmtableinner .e5admin-rdm-rdmtable .statusimgdiv img {
  margin-top: 2px;
  height: 15px;
}

.e5admin-rdm-list .e5admin-rdm-rdmtableouter .e5admin-rdm-rdmtableinner .e5admin-rdm-rdmtable .rdmactioncell {
  margin-top: 5px;
}

.e5admin-rdmcancelconfirm {
  padding: 10px;
  width: 400px;
}

.e5admin-rdm-fullresponsedial {
  overflow: auto;
}

.e5admin-rdm-fullresponsedial .e5admin-rdm-fullresponsecontent {
  padding: 0 10px 0 0;
}

.rdm-status-id-1 {
  color: #8F2FBC;
  padding: 5px;
  border-radius: 15px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #8F2FBC1A;
}

.rdm-status-id-2 {
  color: #FE5D18;
  padding: 5px;
  border-radius: 15px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #FE5D181A;
}

.rdm-status-id-3 {
  color: #3D87F7;
  padding: 5px;
  border-radius: 15px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #3D87F71A;
}

.rdm-status-id-4 {
  color: #66A400;
  padding: 5px;
  border-radius: 15px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #66A4001A;
}

.rdm-status-id-5 {
  color: #FA9735;
  padding: 5px;
  border-radius: 15px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #FA97351A;
}

.rdm-status-id-6 {
  color: #fbb166;
  padding: 5px;
  border-radius: 15px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #ffefe8;
}

.rdm-status-id-7 {
  color: #C80000;
  padding: 5px;
  border-radius: 15px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #C800001A;
}

.rdm-status-id-default {
  color: #faa047;
  padding: 5px;
  border-radius: 15px;
  padding-left: 7px;
  padding-right: 7px;
  background-color: #fff6eb;
}

.datepicker-wrapper {
  display: flex;
}

.datepicker-from-rdm {
  margin-right: 10px !important;
  margin-left: 2px !important;
  width: 200px;
  padding-bottom: 10px;
  box-sizing: border-box;
}

.datepicker-from-rdm, .datepicker-to-rdm .MuiInputBase-root {
  height: 35px !important;
}

.datepicker-wrapper label {
  font-size: 14px !important;
  top: -5px !important;
}

/* .datepicker-from-rdm, .datepicker-to-rdm label {
  font-size: 14px !important;
  top: -5px !important;
} */

.datepicker-to-rdm {
  margin-right: 10px;
  width: 200px;
}

.e5filter-header {
  justify-content: space-between;
  align-items: center;
}

.e5-rdm-adm-nomargin {
  margin: 0 !important;
}

.bp3-dialog-footer .e5-rdm-savebutton {
  background: #2BA2171A !important;
  color: #2BA217 !important;
}

.bp3-dialog-footer .e5-rdm-savebutton:focus,
.bp3-dialog-footer .e5-rdm-savebutton:hover{
  background-color: transparent !important;
  border: 1.5px solid #2BA217 !important;
}

.bp3-dialog-footer .e5-rdm-deletebutton {
  background: #FF00001A !important;
  color: #FF0000 !important;
}

.bp3-dialog-footer .e5-rdm-deletebutton:focus,
.bp3-dialog-footer .e5-rdm-deletebutton:hover{
  background-color: transparent !important;
  border: 1.5px solid #FF0000 !important;
}

.bp3-dialog-footer .e5-rdm-savenochangebutton:focus,
.bp3-dialog-footer .e5-rdm-savenochangebutton:hover {
  background-color: transparent !important;
  border: 1.5px solid #0080FF !important;
}

.e5admin-rdm-marginauto {
  margin: auto;
}

.e5filter-header-menu {
  cursor: default;
}

.e5admin-rdm-filterbuttonreset {
  font-size: 14px;
  padding: 14px;
  background-color: #FFFFFF;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  }
  
.e5filter-menu:hover,
.e5admin-rdm-filterbuttonreset:hover {
  border: none;
  background-color: #EFF4F6;
}