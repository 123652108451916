.e5h-event {
  height: 100px;
  padding-right: 20px;
}

.event-table>table {
  border-spacing: 0;
  width: 100%;
}

.sorted-column {
  display: flex;
}

th,
td {
  border: solid 1px #A0A0A0;
  align-items: center;
  height: 30px;
  padding: 5px 10px;
  margin: 0;
  text-align: center;
}

td {
  background: white;
}

th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #E5F2FF;
}

.e5h-event.event-table>div>div {
  font-size: 0.9em;
}

.e5h-event.e5column-5.e5compo.event-table>div>div>div {
  border: solid 1px #A0A0A0;
  align-items: center;
  height: 30px;
}

.event-tab {
  font-weight: bold;
  width: 100%;
  text-align: center;
  justify-content: center;
  padding: 5px 10px;
}

.event-table-data {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  padding: 5px 10px;
}

.filter-network {
  padding: 5px 0 0 0;
}


.e5page .bp3-tab-list {
  background-color: #E5F2FF;
  margin-bottom: 20px;
}

/* .e5page .bp3-tab-panel {
  overflow: auto;
} */

.e5h-eventlogs .bp3-tab-list {
  background-color: transparent !important;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.e5h-eventlogs .bp3-tab-list .bp3-tab-indicator-wrapper {
  display: none;
}

.e5h-eventlogs .bp3-tab-list>div {
  text-align: center;
  background: rgba(0, 135, 184, 0.1) !important;
  border-radius: 10px;
  flex: 1 1 0;
  color: #0080FF;
  font-weight: 700;
  font-size: 14px;
  border: 1.5px solid transparent;
  padding: 5px 0;
}

.e5h-eventlogs .bp3-tab-list div[aria-selected="true"] {
  background: #0080FF !important;
  color: white !important;
}

.e5h-eventlogs .bp3-tab-list .initial-tab {
  width: calc((100% - 60px) / 4);
  flex: none;
  margin-bottom: 15px;
}

.e5h-eventlogs .bp3-tab-list :nth-child(5) {
  margin-right: 0;
}

.e5h-eventlogs .bp3-tab-list>div:hover {
  border: 1.5px solid #0080FF
}

.e5h-eventlogs .switch-container {
  margin-left: auto;
  align-self: center;
}

.e5h-eventlogs .filter label {
  transform: translate(14px, 9px) scale(1);
}

.e5h-eventlogs .filter label.Mui-focused {
  transform: translate(14px, -9px) scale(0.75);
}

.e5h-eventlogs .filter label.MuiFormLabel-filled {
  transform: translate(14px, -9px) scale(0.75);
}

.e5h-eventlogs .filter .MuiInputBase-input {
  padding: 8.5px 14px;
}

.e5h-eventlogs-content {
  border-image-slice: 1;
  border-top: 2px solid !important;
  border-image-source: linear-gradient(90deg, #0080FF 0%, #00D6D4 99.92%);
  padding-top: 15px !important;
}

.e5h-eventlogs-ext {
  margin-right: 10px;
}

.e5h-eventlogs-gtw {
  margin-right: 10px;
  margin-left: 5px;
}