.e5wifihstatus {
  align-items: stretch;
  font-size: 0.8em;
}

.e5wifihstatus > div {
  white-space: nowrap;
  padding: 5px;
  font-weight: bold;
  background: #FFFFFF;
  border-radius: 10px;
}

.e5wifihstatus span {
  color: black !important;
  font-size: 14px;
  font-weight: 600;
}

.e5wifihstatus > div .e5wifihstatustitle {
  font-weight: 500;
}
