  .e5wifih-metrics .bp3-tab-list {
    background-color: transparent !important;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  .e5wifih-metrics .bp3-tab-list .bp3-tab-indicator-wrapper {
    display: none;
  }

  .e5wifih-metrics .bp3-tab-list>div {
    text-align: center;
    background: rgba(0, 135, 184, 0.1) !important;
    border-radius: 10px;
    flex: 1 1 0;
    color: #0080FF;
    font-weight: 700;
    font-size: 14px;
    border: 1.5px solid transparent;
    padding: 5px 0;
  }

  .e5wifih-metrics .bp3-tab-list .active-tab {
    background: #0080FF !important;
    color: white !important;
  }

  .e5wifih-metrics .bp3-tab-list div[aria-selected="true"] {
    background: #0080FF !important;
    color: white !important;
  }

  .e5wifih-metrics .bp3-tab-list .initial-tab {
    width: calc((100% - 40px) / 3);
    flex: none;
    margin-bottom: 15px;
  }

  .e5wifih-metrics .bp3-tab-list .wifi-tab {
    width: calc((((100% - 40px) / 3) - 9px) / 3);

    /* padding-left: 3px;
    padding-right: 3px; */
    flex: none;
  }

  .wifi-tab-with-margin {
    margin-right: 5px !important;
  }

  .e5wifih-metrics .bp3-tab-list .sys-tab {
    width: calc((((100% - 40px) / 3) - 20px) / 2);
    flex: none;
  }

  .e5wifih-metrics .bp3-tab-list .sys-tab.first {
    margin-left: auto;
  }


  .e5wifih-metrics .bp3-tab-list :nth-child(4) {
    margin-right: 0;
  }

  .e5wifih-metrics .bp3-tab-list>div:hover {
    border: 1.5px solid #0080FF
  }

  .e5wifih-metrics .e5wifih-metrics-content {
    overflow-y: hidden;
  }

  .e5wifih-metrics .e5wifih-metrics-content .tabdesc {
    padding: 5px 0;
    font-size: 0.9em;
  }

  .e5wifih-metrics .e5wifih-metrics-content .e5wifih-metrics-components {
    /*height: 100px;*/
    overflow: visible;
  }

  .e5wifih-metrics .e5wifih-metrics-content .e5wifih-metrics-components .e5wifih-metrics-leftcolumn {
    overflow-y: auto;
    overflow-x: hidden;
    width: 240px;
  }

  .e5wifih-metrics .e5wifih-metrics-content .e5wifih-metrics-components .e5wifih-metrics-leftcolumn .e5wifih-metrics-householdelems .e5wifih-metrics-interfacetitle {
    padding-top: 10px;
  }

  .e5wifih-metrics .e5wifih-metrics-content .e5wifih-metrics-components .e5metrics-scrollable {
    overflow-y: auto;
    padding-right: 20px;
  }

  .e5wifih-metrics .e5wifih-metrics-content .e5wifih-metrics-components .e5wifih-metrics-rightsource {
    text-align: right;
  }

  .e5wifih-metrics .e5wifih-metrics-content .e5wifih-metrics-components .e5wifih-metrics-leftright {
    margin-top: 20px;
  }

  .e5wifih-metrics .e5wifih-metrics-content .e5wifih-metrics-components .e5wifih-metrics-desc {
    font-size: 0.9em;
    text-align: center;
    color: #707070;
  }


  .e5wifih-metrics .bp3-tab-list {
    background-color: transparent !important;
    margin-bottom: 20px;
    flex-wrap: wrap;
  }

  .hmetrics-initial-tab {}