.e5wificb-wan {
  height: 100px;
  padding-right: 20px;
}

.e5wificb-wan > div > div > div {
  height: 384px;
}

.e5wificb-wan > div > div > div.e5angular-gauge > div {
  align-items: center;
}
