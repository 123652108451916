.e5admin-rdm-popup-forms {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}

.e5admin-rdm-single-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
}

.e5admin-rdm-popup-margin {
  margin-top: 25px;
}

.e5admin-rdm-popup .bp3-input {
  height: 35px;
  width: 265px;
}

.e5admin-rdm-popup .MuiFormControl-root {
  width: 100%;
}

.e5admin-rdm-popup .e5admin-rdm-comment {
  width: 100%;
}

.e5admin-rdm-popup .MuiInputBase-root {
  height: 35px;
}

.bp3-dialog {
  padding: 15px;
  width: max-content;
}

.bp3-input,
.bp3-dialog-body input,
.bp3-dialog-body select {
  border: none;
}

.e5-admin-rdm-separator {
  background: linear-gradient(90deg, #0080FF 0%, #00D6D4 99.92%);
  height: 2px;
  border: none;
  margin-top: 17px;
  margin-bottom: 17px;
}

.e5admin-rdm-response {
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: center;
  color: #0080FF;
}

.e5admin-rdm-highlighted-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 17.07px;
  text-align: left;
  color: #0080FF;
  text-decoration: underline;
}

.e5admin-popup-verticalspacement {
  margin-top: 15px;
}

.e5admin-popup-horizontalspacement {
  margin-left: 10px;
}