.e5wifih-metrics-wifi-link .e5pie-chart {
  width: 50%;
  min-height: 350px;
}

.e5wifih-metrics-wifi-link .filterbloc label {
  transform: translate(14px, 9px) scale(1);
}

.e5wifih-metrics-wifi-link .filterbloc label.Mui-focused {
  transform: translate(14px, -9px) scale(0.75);
}

.e5wifih-metrics-wifi-link .filterbloc label.MuiFormLabel-filled {
  transform: translate(14px, -9px) scale(0.75);
}

.e5wifih-metrics-wifi-link .filterbloc .MuiInputBase-input {
  padding: 8.5px 14px;
}

.e5wifih-metrics-chart-title {
  color: black;
  font-size: 16px;
  width: 100%;
  text-align: center;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.e5compotsubtitile {
  font-weight: bold;
}