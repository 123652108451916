.e5admin-users-list .e5admin-users-header .e5admin-users-titleline {
  align-items: center;
  height: 30px;
}

.e5admin-users-list .e5admin-users-header .e5admin-users-titleline button {
  background: rgba(0, 135, 184, 0.1);
  border-radius: 10px;
  color: #0080FF;
  font-weight: 500;
  font-size: 14px;
  border: none;
  box-shadow: none;
}

.e5admin-users-list .e5admin-users-header .e5admin-users-titleline .delete-filters-button {
  background: rgba(0, 135, 184, 0.1);
  border-radius: 10px;
  color: #0080FF;
  font-weight: 500;
  font-size: 14px;
  width: 150px;
  min-width: 150px;
  border: none;
  box-shadow: none;
  padding: 5px 0;
  margin-bottom: 5px !important;
}

.e5admin-users-titleline .MuiInputBase-root {
  border-radius: 12px;
}

.e5admin-users-filterbloc-search {
  height: 40px;
  margin-right: 15px;
}

.filter-icon {
  margin-left: 15px;
  cursor: pointer;
}


.updateButton {
  color: #2BA217;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background: rgba(43, 162, 23, 0.10);
  width: 203px;
  height: 35px;
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteButton {
  color: #F00;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  background: rgba(255, 0, 0, 0.10);
  width: 196px;
  height: 35px;
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  padding-left: 5px;
  box-sizing: content-box;
}

.delete-filters-button {
  background-color: lightblue;
  margin-bottom: 18px;
  font-size: 14px;
  /* height: 14px; */
  border-radius: 20px;
  background-image: none;
}

.e5admin-users-list .bp3-icon {
  color: #0080FF;
}

.e5admin-users-header {
  background-color: #fafcff !important;
  ;
}

.e5admin-users-list .e5admin-users-header .e5admin-users-filterline {
  align-items: flex-end;
}

.e5admin-users-list .e5admin-users-header .e5admin-users-filterline.rolesfilter,
.e5admin-users-list .e5admin-users-header .e5admin-users-filterline.aclgroupsfilter {
  font-size: 0.8em;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 60vw;
  gap: 5px;
}

.e5admin-users-list .e5admin-users-header .e5admin-users-filterline.rolesfilter label.bp3-control,
.e5admin-users-list .e5admin-users-header .e5admin-users-filterline.aclgroupsfilter label.bp3-control {
  margin-bottom: 0;
}

.e5admin-users-list .e5admin-users-header .e5admin-users-filterbloc .label {
  font-size: 0.8em;
}

.e5admin-users-list .e5admin-users-header .e5admin-users-filterbloc {
  text-align: start;
  color: #0080FF;
  font-weight: 600;
  font-size: 15px;
  width: unset;
  position: relative;
  margin-bottom: 3px;
}

.role-in-table {
  padding: 5px;
  border-radius: 10px;
  background-color: lightblue;
  margin-right: 2px;
}

.filterModal {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px;
  width: 200px;
  background: white;
  z-index: 1000;
  border: 1px solid gray;
  font-size: 14px;
  font-weight: 500;
}

.filterHeader {
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
}

.filterButton {
  cursor: pointer;
  border-radius: 15px;
  padding: 5px 10px
}

.filterButton:hover {
  background-color: #EAEDF1;
  border-radius: 15px;
  padding: 5px 10px
}

.e5admin-users-list .e5admin-users-header .e5admin-users-filterbloc.hasfilter {
  border: solid 1px #AA0000;
}

.e5admin-users-list .e5admin-usertableouter {
  overflow: visible;
}

.e5admin-users-list .e5admin-usertableouter .e5admin-usertableinner {
  width: 100px;
}

.e5admin-users-list .e5admin-usertableouter .e5admin-usertableinner .e5admin-usertable {
  border: solid 1px #A0A0A0;
}

.e5admin-users-list .e5admin-usertableouter .e5admin-usertableinner .e5admin-usertable .tbheadname {
  font-size: 0.8em;
  display: inline-block;
  padding-top: 5px;
}

.e5admin-users-list .e5admin-usertableouter .e5admin-usertableinner .e5admin-usertable .tbhead {
  text-align: center;
}

.e5admin-userdelconfirm {
  padding: 10px;
  width: 200px;
}

.e5admin-users-header {
  border-image-slice: 1;
  border-bottom: 2px solid !important;
  border-image-source: linear-gradient(90deg, #0080FF 0%, #00D6D4 99.92%);
  padding-bottom: 15px !important;
}

.admin-user-update-many {
  display: flex;
  padding: 10px;
  font-weight: bold;
}

.admin-user-update-many-left {
  width: 334px;
}

.admin-user-update-many-right {
  width: 300px;
}

.admin-user-update-many-roles {
  display: flex;
  flex-direction: column;
}

.bp3-control input:checked~.bp3-control-indicator {
  background-color: #0080FF;
}

.bp3-control.bp3-checkbox input:checked~.bp3-control-indicator::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' %3E%3Ccircle cx='8' cy='8' r='4' stroke-width='3' fill='white' /%3E%3C/svg%3E");
}

.bp3-dialog.delete-user-modal {
  padding-bottom: 0 !important;
}

.delete-user-modal .bp3-dialog-header {
  background-color: #fafcff;
  min-height: 60px !important;
}

.update-user-modal .bp3-dialog-header {
  min-height: 60px !important;
}

.bp3-dialog.update-user-modal {
  padding-bottom: 0 !important;
}