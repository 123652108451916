.e5cb-sys-temperature {
  height: 100px;
  padding-right: 20px;
}

.e5cb-sys-temperature > div > div > div {
  height: 384px;
}

.e5cb-sys-temperature > div > div > div.e5angular-gauge > div {
  align-items: center;
}

.e5cb-sys-temperature > div:first-child > div > div {
  height: 400px;
}

.e5cb-sys-temperature .e5pie-chart {
  height: 400px;
}
