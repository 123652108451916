.circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0080FF;
    margin: 0 10px;
    border-radius: 50%;
}

.inner-circle {
    color: #FFFFFF;
}

.profile-picture {
    border-radius: 50%;
    background-position: center;
    object-fit: cover;
}

.camera-container {
    position: relative;
}

.camera-container .upload {
    position: absolute;
    top: 20px;
    background: #0080FF;
    box-shadow: 0px 3px 14px 2px #0000001F;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 7px;
    cursor: pointer;
}

.camera-container .upload.no-image {
    left: -15px;
}

.camera-container .upload.has-image {
    right: 1px;
}