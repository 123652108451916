.text-center-start {
    display: flex;
    align-items: center;
    justify-content: start;
}

.center-alignment {
    display: flex;
    align-items: center;
    justify-content: center;
}

.underline-text {
    text-decoration: underline;
}

.form-spacement {
    margin-left: 1rem;
}

.text-spacement {
    margin-left: .5rem;
}

.top-spacement {
    margin-top: 1rem;
}

.equipment-rdm {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.ttlSelect {
    width: 140px;
}

.typeSelect {
    width: 225px;
}

#commentInput {
    width: 465px;
}

.equipment-rdm .bp3-input {
    height: 35px;
    width: 265px;
}

.equipment-rdm button,
.rdm-equipment-table-details {
    background: rgba(0, 135, 184, 0.1) !important;
    border-radius: 10px;
    border: none;
    box-shadow: none !important;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 25px !important;
    color: #0080FF !important;
}

.equipment-rdm .MuiInputBase-root {
    height: 35px;
}

.equipment-rdm-dialog {
    min-width: 900px;
    min-height: 300px;
}

.dialog-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.5px;
    text-align: center;
    color: #0080FF;
}

.dialog-title-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px;
}

.dialog-text-highlighted {
    text-decoration: underline #0080FF;
    color: #0080FF;
}

.equipment-rdm-dialog .bp3-dialog-footer .bp3-dialog-footer-actions {
    justify-content: flex-end;
}

.e5equipment-rdm .bp3-button:hover,
.e5equipment-rdm .bp3-button:focus {
    background-color: transparent !important;
    color: #0080FF;
    border: 1.5px solid #0080FF;
}

.cancel {
    cursor: pointer;
}