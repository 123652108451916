.e5mainmenu {
  background-color: #fff;
  color: #595959;
  padding: 10px;
  fill: #595959;
  overflow: hidden;
  border-radius: 0;
  min-width: 185px;
  width: 185px;
}

.e5mainmenu .bp3-menu-divider {
  margin: 12.5px 20px;
  border-color: #FFFFFF;
}

.e5mainmenu .e5mainmenucontent {
  overflow-y: auto;
  overflow-x: hidden;
}

.e5mainmenu .expand-button {
  margin: 2px 3px 0 0;
  padding: 0 2px 0 0;
  /* border: solid #595959 1.5px; */
  border-radius: 100%;
}

.e5mainmenu .expand-button:focus {
  outline: none;
}

.e5mainmenu .expand-button:hover {
  background-color: #ffffff88;
}

.e5mainmenu .e5menucopyright {
  font-size: 0.8em;
  font-style: italic;
  white-space: nowrap;
  padding: 5px 10px;
}

.e5mainmenu.narrow {
  min-width: 40px;
  width: 40px;
  padding: 5px;
}

.e5mainmenu.narrow .expand-button {
  padding: 0 0 0 2px;
}

.main-toggle-open {
  width: 14px;
  height: 14px;
  position: absolute;
  margin-left: -5px;
  margin-top: -7px;
}

.main-toggle-close {
  width: 14px;
  height: 14px;
  position: absolute;
  margin-left: -23px;
  margin-top: -7px;
}

.wificb-dashboard-search-svg:focus {
  outline: none !important;
}