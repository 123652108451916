.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table {
  display: grid;
  grid-template-columns: 265px repeat(3, 81px);
}

.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div>div:not(:first-child) {
  background-color: white;
  font-weight: 500;
  font-size: 14px;
}


.e5wifih-metrics-leftcolumn {
  display: none;
}

.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div:first-child>div:first-child {
  border-top-left-radius: 10px;
}

.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div:last-child>div:first-child {
  border-top-right-radius: 10px;
}

.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div:first-child>div:last-child {
  border-bottom-left-radius: 10px;
}

.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div:last-child>div:last-child {
  border-bottom-right-radius: 10px;
}

.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div>div {
  border-left: solid 2px #F3F5F8;
}


.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div:last-child>div {
  border-right: solid 2px #F3F5F8;
}

.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div>div:last-child {
  border-bottom: solid 2px #F3F5F8;
}


.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div>div {
  height: 58px;
  padding: 0 5px;
  display: flex;
  align-items: center;
}

.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div:not(:first-child)>div {
  justify-content: center;
}

.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div>div:first-child {
  background-color: #EAEDF1;
  font-weight: 600;
  font-size: 18px;
  text-align: center;
  justify-content: center;
}

.e5wifih-metrics-nodes .e5wifih-metrics-nodes-summary-table>div>div:not(:first-child) {
  border-top: solid 2px #F3F5F8;
}


.e5wifi-metrics-equip .e5pie-chart {
  width: 100%;
}


.e5wifih-metrics-nodes .filterbloc label {
  transform: translate(14px, 9px) scale(1);
}

.e5wifih-metrics-nodes .filterbloc label.Mui-focused {
  transform: translate(14px, -9px) scale(0.75);
}

.e5wifih-metrics-nodes .filterbloc label.MuiFormLabel-filled {
  transform: translate(14px, -9px) scale(0.75);
}

.e5wifih-metrics-nodes .filterbloc .MuiInputBase-input {
  padding: 8.5px 14px;
}

.e5wifih-metrics-nodes-select-wrapper {
  display: flex;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.e5wifih-metrics-nodes-select-svg {
  margin-right: 10px;
}

.e5wifih-metrics-station-options {
  display: grid;
  grid-template-columns: .1fr 1fr;
  gap: 10px;
}

.e5wifih-metrics-station-icon {
  width: 20px;
}