.e5cb-overview {
  height: 100px;
  padding-right: 20px;
}

.e5cb-overview > div:not(:first-child) > div > div {
  height: 384px;
}

.e5cb-overview > div:not(:first-child) > div > div.e5angular-gauge > div {
  align-items: center;
}
