.e5cb-dashboard-filters {
  align-items: center;
  background-color: transparent;
  padding: 5px;
  color: #0080FF;
}

.dashboard-filters .e5wifih-filters {
  padding-left: 0;
}

.e5cb-dashboard-filters select {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid #0080FF;
  box-shadow: none;
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  box-shadow: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  height: 100%;
}

.e5cb-dashboard-filters .filters-poplist {
  width: 250px;
}

.search-icon {
  width: auto;
  height: 23px;
}