.ini__tel-input {
    padding-top: 82px;
}

.iti__selected-flag {
    background-color: #F4F7F9;
    justify-content: center;
    border-radius: 5px 0 0 5px;
    padding: 20px 16px 20px 10px !important;
}

#phone {
    border: 1px solid #BEBEBD;
    border-radius: 5px; 
    height: 60px;
    width: 390px;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    padding-top: 27px;
    padding-left: 75px;
}

#phone::placeholder {
    color: #BEBEBD;
}

.phone-container {
    position: relative;
    margin-bottom: 16px;
}

.phone-number {
    position: absolute;
    z-index: 2;
    font-family: Montserrat, sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #767675;
    top: 10px;
    left: 76px;
}

.invalid-field {
    border: 1px solid #FF0000 !important;
}