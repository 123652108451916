.e5cb-dashboard .bp3-tab-list {
  background-color: transparent !important;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.e5cb-dashboard .bp3-tab-list .bp3-tab-indicator-wrapper {
  display: none;
}

.e5cb-dashboard .bp3-tab-list > div {
  text-align: center;
  background: rgba(0, 135, 184, 0.1) !important;
  border-radius: 10px;
  flex: 1 1 0;
  color: #0080FF;
  font-weight: 700;
  font-size: 14px;
  border: 1.5px solid transparent;
  padding: 5px 0;
}

.e5cb-dashboard .bp3-tab-list div[aria-selected="true"] {
  background: #0080FF !important;
  color: white !important;
}

.e5cb-dashboard .bp3-tab-list .initial-tab {
  width: calc((100% - 60px) / 4);
  flex: none;
  margin-bottom: 15px;
}

.e5cb-dashboard .bp3-tab-list :nth-child(5) {
  margin-right: 0;
}

.e5cb-dashboard .bp3-tab-list > div:hover {
  border: 1.5px solid #0080FF
}


.e5cb-dashboard-dialog{
  max-height: calc(100vh - 60px);
  display: inline-flex;
}

.e5cb-dashboard-dialog .proc-table{
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

/* .e5cb-dashboard .bp3-tab-panel {
  overflow: auto;
} */

.e5cb-dashboard .tab-disabled {
  font-size: 1.75em;
}

.e5cb-dashboard .topcontentswitch {
  padding-left: 20px;
}

.e5cb-dashboard .topcontentswitch .modenamestr {
  padding-top: 3px;
}

.e5cb-dashboard-downloaddiv {
  padding: 10px;
}

.e5cb-dashboard-downloaddiv .title {
  max-width: 400px;
  padding-bottom: 10px;
}

textarea.bp3-input.e5cb-dashboard-nilistarea {
  height: 400px;
}

.e5cb-incidents-legend {
  max-width: 400px;
  padding: 10px;
}

.divider{
  background: linear-gradient(90deg, #0080FF 0%, #00D6D4 99.92%);
  height: 2px;
  width: 100%;
}
