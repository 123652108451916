.e5admin-users-roles-legend {
  display: inline-block;
}

.e5admin-users-roles-legend-table {
  font-size: 0.8em;
  width: 925px;
  display: grid;
  grid-template-columns: 180px repeat(7, calc((100% - 180px)/7));
  border-radius: 10px;
}

.e5admin-users-roles-legend-table > div:first-child {
  font-weight: 600;
}

.e5admin-users-roles-legend-table > div:not(:first-child) {
  border-left: solid 1px #F3F5F8;
}

.e5admin-users-roles-legend-table > div:not(:first-child) > div:not(:first-child) {
  padding: 0 45%;
}

.e5admin-users-roles-legend-table > div > div {
  height: 25px;
  padding: 0 5px;
  display: flex;
  align-items: center;
}

.e5admin-users-roles-legend-table > div > div:first-child {
  background: #EAEDF1;
  font-weight: 600;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.e5admin-users-roles-legend-table > div > div:not(:first-child) {
  border-top: solid 1px #F3F5F8;
}
