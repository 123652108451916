.e5admin-rdm-req-legend {
  display: inline-block;
}

.e5admin-rdm-req-legend-table {
  font-size: 0.8em;
  padding: 5px;
  width: 700px;
  display: grid;
  grid-template-columns: 38% 62%;
}

.e5admin-rdm-req-legend-table > div:first-child {
  background-color: #E5F2FF;
  font-weight: 1000;
}

.e5admin-rdm-req-legend-table > div:not(:first-child) {
  border-left: solid 1px #A0A0A0;
}

.e5admin-rdm-req-legend-table > div > div {
  padding: 0 5px;
  height: 25px;
  display: flex;
  align-items: center;
}

.e5admin-rdm-req-legend-table > div > div:first-child {
  background-color: #0080FF;
  font-weight: 1000;
}

.e5admin-rdm-req-legend-table > div > div:not(:first-child) {
  border-top: solid 1px #A0A0A0;
}
