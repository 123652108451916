.e5admin-rdm-response .e5admin-rdm-responseheader > div {
  display: inline-block;
  padding: 0 20px 0 0;
}

.e5admin-rdm-response .e5admin-rdm-responsetable {
  margin: 2px;
}

.e5admin-rdm-response .e5admin-rdm-responsetable .tbhead {
  text-align: center;
  font-weight: bold;
  font-size: 0.9em;
}

.e5admin-rdm-response textarea {
  width: 100%;
  height: 370px;
  margin: 2px;
  resize: none;
}
