.stations-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: #F9FCFF;

}

.stations-content {
    width: 100%;
    height: calc(100% - 133px - 20px - 50px - 20px);
    overflow-y: auto;
    border-image-slice: 1;
    border-top: 2px solid !important;
    border-image-source: linear-gradient(90deg, #0080FF 0%, #00D6D4 99.92%);
    padding-top: 15px;
}

.stations-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    margin-bottom: 15px;
    height: 35px;
}

.stations-list {
    width: 100%;
    height: calc(100% - 50px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.stations-list-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    border-radius: 10px;
}

.list-item-header {
    width: 100%;
    height: 69px;
    display: flex;
    align-items: center;
    padding: 10px;
    background: #F3F5F8;
    border-radius: 10px 10px 0px 0px;
    cursor: pointer;
}

.closed-header {
    border-radius: 10px;
}

.list-item-content {
    width: 100%;
    padding: 10px;
    display: none;
    flex-direction: column;
    background: white;
}

.top-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 180px;
    width: 100%;
}

.bot-content {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
}

.content-item {
    width: 50%;
    height: 30px;
    display: flex;
    align-items: center;
}

.stations-page .content-item-label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 150px;
    display: flex;
    align-items: center;
}

.stations-page .station-item-label-name {
    margin-right: 5px;
}

.stations-page .station-icon-info {
    width: 18px;
    height: 18px;
    display: inline-block;
    background-image: url('/src/assets/info-black.svg');
    background-size: cover;
}

.stations-page .station-icon-info:hover {
    background-image: url('/src/assets/info-white.svg');
}

.stations-page .content-popover-item {
    background-color: #000;
    color: #FFF;
    padding: 5px;
    border-radius: 5px;
}

.stations-page .bp3-popover-arrow-fill {
    fill: #000 !important;
}

.content-item-value {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.show-details {
    display: flex;
}

.header-image {
    width: 30px;
    height: 34px;
    margin-right: 15px;
}

.header-title-container {
    width: calc(100% - 11px - 12px - 20px - 30px - 15px);
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.title-item {
    width: 50%;
    height: 50%;
    display: flex;
    align-items: center;
}

.title-item-label {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 5px;
}

.title-item-content {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.header-metrics {
    height: auto;
    width: 20px;
    margin-right: 12px;
    cursor: pointer;
}

.header-chevron {
    width: 11px;
    height: auto;
}

.stations-progress-bar-wrapper {
    display: flex;
    align-items: baseline;


}

.stations-progress-bar {
    position: relative;
    width: 50%;
    height: 10px;
    background-color: #4DD0E1;
    margin-left: 20px;
    border-radius: 10px;
    margin-right: 70px;
}

.stations-progress-bar-start {
    position: absolute;
    left: 0;
    margin-top: 10px;
    font-size: 10px;
}

.stations-progress-bar-end {
    position: absolute;
    right: 0;
    margin-top: 10px;
    font-size: 10px;
}

.stations-progress-bar-status-conntect {
    width: 20px;
    height: 10px;
    background-color: #4DD0E1;
    border-radius: 10px;
    margin-right: 5px;
}

.stations-progress-bar-status-disconntect {
    width: 20px;
    height: 10px;
    background-color: lightgray;
    border-radius: 10px;
    margin-right: 5px;

}

.stations-progress-bar-status-wrapper {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-left: 10px;
}