.e5h-connectivity-selected-item {
    position: absolute;
    margin: 10px;
    right: 22px;
    z-index: 9;
    max-width: 700px;
    max-height: 445px;
    overflow-y: auto;
    border-radius: 10px;
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.25), 5px -5px 10px rgba(0, 0, 0, 0.25), 5px 5px 10px rgba(0, 0, 0, 0.25), -5px -5px 10px rgba(0, 0, 0, 0.25);
}

.onedevice-wrapper {
    position: absolute;
    display: flex;
    margin-top: 80px;
    margin-left: 10px;
    /* //width: 500px; */
    background-color: rgba(221, 221, 221, .8);
    border-radius: 10px;
    padding: 5px;
}

.device-image {
    margin-left: 4px;
    margin-top: 23px;
    width: 50px;
    height: 50px;
}


.onedevice-wrapper-left {
    width: 53px;
    margin-right: 10px;
}



.onedevice-wrapper-middle {
    /* //width: 250px; */
    margin-right: 50px;
}

.onedevice-wrapper-right {
    /* width: 200px; */
}

.onedevice-item {
    display: flex;
}

.onedevice-item .title {
    margin-right: 5px;
}

.onedevice-item .value {
    font-weight: bold;
}

.red {
    width: 44px;
    height: 6px;
    margin-top: 6.5px;
    border-radius: 10px;
    background-color: red;
}

.orange {
    width: 44px;
    height: 6px;
    margin-top: 6.5px;
    border-radius: 10px;
    background-color: orange;
}

.yellow {
    width: 44px;
    height: 6px;
    margin-top: 6.5px;
    border-radius: 10px;
    background-color: darkgoldenrod;
}

.green {
    width: 44px;
    height: 6px;
    margin-top: 6.5px;
    border-radius: 10px;
    background-color: darkgreen;
}

.darkgreen {
    width: 44px;
    height: 6px;
    margin-top: 6.5px;
    border-radius: 10px;
    background-color: darkgreen;
}